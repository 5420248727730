import { Button, Link, Menu, MenuItem, Typography } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useState } from "react";
import { Link as RouterLink } from "react-router";
import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { getAuth, signOut as signOutFirebase } from "firebase/auth";
import { useDispatch } from "react-redux";
import { backendApi } from "store/api/enhancedApi";
import { useTenantData } from "contexts/tenant";

export const SettingsMenu = () => {
  const tenant = useTenant();
  const tenantData = useTenantData();
  const { t } = useTenantTranslation();
  const auth = getAuth();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await signOutFirebase(auth);

    // Resetting the whole API state here is probably overkill, but is a good
    // way to guarantee that no information is carried between users until we
    // can work out how to invalidate only the current user query
    dispatch(backendApi.util.resetApiState());
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="inherit"
        sx={{
          px: 2,
          py: 0.75,
          color: "white",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "left",
        }}
        endIcon={<MenuRoundedIcon />}
      >
        {tenantData.long_name}
      </Button>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: 1,
          // make the background of the menu black
          "& .MuiPaper-root": {
            backgroundColor: "black",
          },
        }}
      >
        <MenuItem>
          <Link
            key="profile"
            to={`/${tenant}/v3/profile-settings`}
            underline="always"
            color="#ffffff"
            component={RouterLink}
            onClick={handleClose}
          >
            <Typography variant="button">{t("userSettings")}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            key="constraints"
            to={`/${tenant}/v3/constraints`}
            underline="always"
            color="#ffffff"
            component={RouterLink}
            onClick={handleClose}
          >
            <Typography variant="button">{t("constraints")}</Typography>
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            void handleSignOut();
            handleClose();
          }}
        >
          <Link underline="always" color="#ffffff">
            <Typography variant="button">{t("signOut")}</Typography>
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

import { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { InputPanel } from "./input/InputPanel";
import { SearchResults } from "./output/SearchResults";
import { HistoryPanel } from "./input/HistoryPanel";
import { mapLoadedUnpack } from "models/loaded";
import { useTabName } from "../common/TabTitle";
import { PlanPanel } from "./output/planPanel";
import { hotkeys, useSearchPageHotkeys } from "hooks/hotkeys";
import { useSearch, useSearchName } from "contexts/search/provider";
import { PeriodTabs } from "../common/periodTabs/tabs";
import { useTenantTranslation } from "hooks/formatters";
import {
  useSearchViewedNotification,
  useTrackSearchIdInUrl,
} from "contexts/search/effects";
import { InventoryEditor } from "./input/inputEditors/InventoryEditor";
import { AvailabilitiesEditor } from "./input/inputEditors/availabilities/AvailabilitiesEditor";
import { ProductionEditor } from "./input/inputEditors/production/productionEditor";
import { TargetInventoryEditor } from "./input/inputEditors/TargetInventoryEditor";
import { TargetBasketsEditor } from "./input/inputEditors/targetBasketsEditor";
import { PeriodVariant, usePeriodVariant } from "hooks/periodIndex";
import { PanelLayout } from "./common/PanelLayout";
import { ConstraintsPageContent } from "../constraints/constraintsPage";
import { RunSearchButtonGroup } from "./output/RunSearchButtonGroup";
import { SearchActionsButton } from "./output/SearchActionsButton";
import { useHotkeys } from "react-hotkeys-hook";

export enum Panel {
  Production,
  Inventory,
  Availabilities,
  TargetInventory,
  TargetBaskets,
  Constraints,
  None,
}

const SearchSaveStatus = () => {
  const { t } = useTenantTranslation();
  const status = useSearch().parameters.server.status;
  const theme = useTheme();
  return (
    <Typography
      sx={{
        marginLeft: "auto !important",
        color: theme.palette.text.secondary,
      }}
    >
      {status !== "success" ? t("saving") : t("saved")}
    </Typography>
  );
};

type BodyProps = {
  setShowHistory: (show: boolean) => void;
  showHistory: boolean;
  setPanel: (panel: Panel) => void;
  panel: Panel;
};

const Body = ({ setShowHistory, showHistory, setPanel, panel }: BodyProps) => {
  const { t } = useTenantTranslation();

  useSearchPageHotkeys(panel, setPanel);
  useTabName(mapLoadedUnpack(useSearchName(), (name) => name));

  const panelWidth = 300;

  const cancelPanel = () => setPanel(Panel.None);

  const periodVariant = usePeriodVariant();

  const openInventoryOnHand =
    panel === Panel.Inventory && periodVariant === PeriodVariant.Schedule;
  const openAvailableScrap = panel === Panel.Availabilities;
  const openForecastProduction = panel === Panel.Production;
  const openTargetInventory = panel === Panel.TargetInventory;
  const openProductionBaskets =
    panel === Panel.TargetBaskets && periodVariant === PeriodVariant.Schedule;
  const openConstraints = panel === Panel.Constraints;

  useHotkeys(hotkeys.constraintsPage, () => setPanel(Panel.None), {
    enabled: openConstraints,
  });

  return (
    <Grid container sx={{ minHeight: "0px", height: "100%" }}>
      <Grid
        item
        sx={{
          height: "100%",
          position: "relative",
          width: `${panelWidth}px`,
        }}
      >
        <InputPanel setPanel={setPanel} />
        <HistoryPanel
          showHistory={showHistory}
          setShowHistory={setShowHistory}
        />
      </Grid>
      <Grid item xs sx={{ height: "100%", position: "relative" }}>
        <SearchResults />
        <PlanPanel width={`calc(100vw - ${panelWidth}px)`} />
        <PanelLayout
          title={t("constraints")}
          open={openConstraints}
          doClose={cancelPanel}
        >
          <ConstraintsPageContent />
        </PanelLayout>
        <PanelLayout
          title={t("inventoryInHand")}
          description={t("inventoryInHandDescription")}
          open={openInventoryOnHand}
          doClose={cancelPanel}
          topRight={<SearchSaveStatus />}
        >
          <InventoryEditor />
        </PanelLayout>
        <PanelLayout
          title={t("availableScrap")}
          description={t("availableScrapDescription")}
          open={openAvailableScrap}
          doClose={cancelPanel}
          topRight={<SearchSaveStatus />}
        >
          <AvailabilitiesEditor />
        </PanelLayout>
        <PanelLayout
          title={t("production")}
          description={t("productionDescription")}
          open={openForecastProduction}
          doClose={cancelPanel}
          topRight={<SearchSaveStatus />}
        >
          <ProductionEditor />
        </PanelLayout>
        <PanelLayout
          title={t("targetInventory")}
          description={t("targetInventoryDescription")}
          open={openTargetInventory}
          doClose={cancelPanel}
          topRight={<SearchSaveStatus />}
        >
          <TargetInventoryEditor />
        </PanelLayout>
        <PanelLayout
          title={t("productionBaskets")}
          description={t("productionBasketsDescription")}
          open={openProductionBaskets}
          doClose={cancelPanel}
          topRight={<SearchSaveStatus />}
        >
          <TargetBasketsEditor />
        </PanelLayout>
      </Grid>
    </Grid>
  );
};

export const SearchPage = () => {
  useSearchViewedNotification();
  useTrackSearchIdInUrl();

  const [showHistory, setShowHistory] = useState(false);

  const [panel, setPanel] = useState(Panel.None);

  useTabName(mapLoadedUnpack(useSearchName(), (name) => name));

  return (
    <PeriodTabs
      trailingComponent={
        <Box sx={{ display: "flex" }}>
          <RunSearchButtonGroup />
          <Box sx={{ ml: 1 }}>
            <SearchActionsButton
              setShowHistory={setShowHistory}
              setPanel={setPanel}
            />
          </Box>
        </Box>
      }
    >
      <Body
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        panel={panel}
        setPanel={setPanel}
      />
    </PeriodTabs>
  );
};

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { ObtainableResultItem, Plan } from "store/api/generatedApi";
import { NumberCell } from "components/common/dataGrid/NumberCell";
import theme from "src/theme";

type Props = {
  plan: Plan;
  open: boolean;
  onClose: () => void;
};

export const ObtainableReport = ({ plan, open, onClose }: Props) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t("obtainableReport")}
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.secondary }}
        >
          {t("obtainableReportDescription")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mb: 4 }}>
        <ObtainableReportTable plan={plan} />
      </DialogContent>
    </Dialog>
  );
};

const ObtainableReportTable = ({ plan }: { plan: Plan }) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const theme = useTheme();

  const data = plan.summary.obtainable_summary;

  const columns: GridColDef<ObtainableResultItem>[] = [
    {
      field: "material_name",
      headerName: t("scrap"),
      sortable: false,
      flex: 0.75,
      display: "flex",
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.secondary }}
        >
          {params.row.material_name}
        </Typography>
      ),
    },
    {
      field: "label",
      headerName: t("label"),
      sortable: false,
      flex: 1.5,
      display: "flex",
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.secondary }}
        >
          {params.row.bundle_name}
        </Typography>
      ),
    },
    {
      field: "required_obtainable",
      headerName: t("obtainableRequired"),
      description: t("obtainableRequiredDescription"),
      sortable: false,
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberOfTotalCell
          value={params.row.required_obtainable}
          total={params.row.max_obtainable}
        />
      ),
    },
    {
      field: "specific_price",
      headerName: `${t("price")} (${units("specific_cost").trim()})`,
      sortable: false,
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberCell
          value={params.row.specific_price}
          typographyProps={{
            sx: {
              color: theme.palette.text.secondary,
            },
          }}
          decimalPlaces={2}
          min={0}
        />
      ),
    },
    {
      field: "total_price",
      headerName: `${t("totalPrice")} (${units("cost").trim()})`,
      width: 150,
      flex: 1,
      display: "flex",
      sortable: false,
      renderCell: (params) => (
        <NumberCell
          value={params.row.specific_price * params.row.required_obtainable}
          typographyProps={{ sx: { color: theme.palette.text.secondary } }}
          decimalPlaces={2}
          min={0}
        />
      ),
    },
  ];

  return (
    <DataGridPremium
      columns={columns}
      rows={data}
      getRowId={(row) => row.material_name + row.created_at}
      autoHeight
    />
  );
};

const NumberOfTotalCell = ({
  value,
  total,
}: {
  value: number;
  total: number;
}) => {
  const numberSerialiser = useNumberSerialiser({ min: 0 });
  const formattedNumber = numberSerialiser.format(value);
  const formattedTotal = numberSerialiser.format(total);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Typography
        variant="body1Mono"
        sx={{ color: theme.palette.text.secondary }}
      >
        {formattedNumber}
        <Box component="span" sx={{ color: "text.disabled" }}>
          /{formattedTotal}
        </Box>
      </Typography>
    </Box>
  );
};

import { Stack, Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { ElementName, elementVariability, Variability } from "helpers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useMemo } from "react";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import {
  ChemicalElement,
  MaterialChemistry,
  MaterialPhysics,
} from "store/api/generatedApi";

type MaterialPropertiesRow = {
  element: string;
  mean: number;
  variability: Variability | "none";
  decimalPlaces: number;
};

export const MaterialProperties = ({
  materialPhysics,
  materialChemistry,
  elements,
}: {
  materialPhysics: MaterialPhysics;
  materialChemistry: Record<number, MaterialChemistry>;
  elements: ChemicalElement[];
}) => {
  const { t } = useTenantTranslation();
  const unitsBracketed = useUnitsFormatter(true);
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser();

  const columns: GridColDef<MaterialPropertiesRow>[] = [
    { field: "element", headerName: t("element"), width: 200 },
    {
      field: "mean",
      headerName: `${t("mean")}${unitsBracketed("yield")}`,
      renderCell: (params) => (
        <NumberCell
          value={params.row.mean}
          decimalPlaces={params.row.decimalPlaces}
        />
      ),
      width: 200,
    },
    {
      field: "variability",
      headerName: t("variability"),
      renderCell: (params) => t(params.row.variability),
      width: 200,
    },
  ];

  const rows = useMaterialProperties(materialChemistry, elements);

  return (
    <Stack sx={{ my: 2, minHeight: "0px" }} gap={2}>
      <DetailValue
        label={t("density")}
        value={`${format(materialPhysics.density, { decimalPlaces: 2 })}${units(
          "density"
        )}`}
      />
      <DetailValue
        label={t("furnaceYield")}
        value={`${format(materialPhysics.yield_percent, {
          decimalPlaces: 1,
        })}${units("yield").trim()}`}
      />
      <DetailValue
        label={t("electricalEnergyConsumption")}
        value={`${format(materialPhysics.electrical_energy_consumption, {
          decimalPlaces: 1,
        })}${units("electrical_energy_consumption")}`}
      />

      <DataGridPremium
        rows={rows}
        columns={columns}
        getRowId={(row) => row.element}
        cellSelection
        ignoreValueFormatterDuringExport
      />
    </Stack>
  );
};

const useMaterialProperties = (
  materialChemistry: Record<number, MaterialChemistry>,
  elements: ChemicalElement[]
): MaterialPropertiesRow[] => {
  return useMemo(() => {
    return elements
      .filter((element) => materialChemistry[element.id] !== undefined)
      .map((element) => {
        const chemistry = materialChemistry[element.id]!;

        return {
          element: element.symbol,
          mean: chemistry.loc,
          variability: elementVariability(
            element.name as ElementName,
            chemistry.scale
          ),
          decimalPlaces: decimalPlaces[element.name as ElementName],
        };
      });
  }, [materialChemistry, elements]);
};

const decimalPlaces: Record<ElementName, number> = {
  copper: 2,
  molybdenum: 3,
  nickel: 2,
  tin: 3,
  chromium: 3,
};

const DetailValue = ({ label, value }: { label: string; value: string }) => {
  return (
    <Typography variant="body2">
      <b style={{ marginLeft: 15 }}>
        <i>{label}: </i>
      </b>
      {value}
    </Typography>
  );
};

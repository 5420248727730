import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { SearchResultsLayout } from "./SearchResultsLayout";

export const LoadingResults = () => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  return (
    <>
      {/* TODO: Make this show definite progress */}
      <LinearProgress sx={{ height: 11 }} />
      <SearchResultsLayout
        isNewSearch={false}
        inputsStale={false}
        constraintsStale={false}
        longestSuboptimalTimeLimit={undefined}
      >
        <Stack gap={3}>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t("loadingResults")}
          </Typography>
        </Stack>
      </SearchResultsLayout>
    </>
  );
};

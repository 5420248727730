import React from "react";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link } from "react-router";

import { useTenantTranslation } from "hooks/formatters";

type Layer = {
  materialName: string;
  mass: number;
};

type BodyProps = {
  steelGradeName: string;
  basketNumber: number;
  layers: Layer[];
  completed: boolean;
};

const layers = [
  { materialName: "RE", mass: 10 },
  { materialName: "RL_STW", mass: 2 },
  { materialName: "E47", mass: 7 },
  { materialName: "E46", mass: 11 },
];

export const CraneBasket = () => {
  return (
    <Body
      steelGradeName="HS2"
      basketNumber={1}
      layers={layers}
      completed={false}
    />
  );
};

const getOpacity = (faded: boolean) => (faded ? 0.5 : 1);

type BasketLabelProps = { children: string; faded: boolean };

const BasketLabel = ({ children, faded }: BasketLabelProps) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[200],
        borderStyle: "solid",
        borderWidth: 1,
        padding: 1,
        opacity: getOpacity(faded),
      }}
    >
      {children}
    </Typography>
  );
};

const Body = ({
  steelGradeName,
  basketNumber,
  layers,
  completed,
}: BodyProps) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "200px 1fr 200px",
        gridTemplateRows: "repeat(2,max-content) 1fr",
        margin: 4,
        height: "calc(100% - 64px)",
        rowGap: 1,
      }}
    >
      <Button
        component={Link}
        to="../"
        startIcon={<ArrowBack />}
        color="primary"
        variant="outlined"
        sx={{ justifySelf: "start" }}
      >
        {t("back")}
      </Button>
      <Typography variant="h1" sx={{ justifySelf: "center", alignSelf: "end" }}>
        {steelGradeName}
      </Typography>
      <Button
        variant="contained"
        sx={{ justifySelf: "end" }}
        disabled={completed}
      >
        {t("complete")}
      </Button>
      <Typography
        variant="h5"
        sx={{ justifySelf: "center", alignSelf: "end", gridColumn: 2 }}
      >
        {t("basket")}&nbsp;{basketNumber}
      </Typography>
      <Box
        sx={{
          gridColumn: "1/-1",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingBottom: 12,
        }}
      >
        <Typography variant="h3" sx={{ opacity: getOpacity(completed) }}>
          {t("totalMass")}: {layers.reduce((acc, layer) => layer.mass + acc, 0)}
          t
        </Typography>
        <Box
          sx={{
            display: "grid",
            position: "relative",
            gridTemplateColumns: "200px 200px",
            gap: 1,
          }}
        >
          {completed ? (
            <CheckCircle
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                zIndex: 2,
                transform: "translate(-50%, -50%)",
                fontSize: 84,
              }}
              color="success"
            />
          ) : null}
          {layers.map((layer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <BasketLabel faded={completed}>{layer.materialName}</BasketLabel>
              <BasketLabel faded={completed}>
                {layer.mass.toString()}
              </BasketLabel>
            </React.Fragment>
          ))}
          <Box
            sx={{
              backgroundColor: theme.palette.grey[50],
              borderColor: theme.palette.grey[200],
              borderStyle: "solid",
              gridColumn: "1/-1",
              borderWidth: 1,
              height: 72,
              borderBottomLeftRadius: 72,
              borderBottomRightRadius: 72,
              display: "grid",
              placeItems: "center",
              opacity: getOpacity(completed),
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {t("basketBottom")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

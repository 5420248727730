import { Tooltip, Typography, useTheme } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import React from "react";

export const useDefaultPlanName = () => {
  const { t } = useTenantTranslation();
  return (index: number | null) =>
    index == null ? t("plan") : `${t("plan")} ${defaultPlanName(index)}`;
};

export const defaultPlanName = (index: number) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numChars =
    index == 0 ? 1 : Math.ceil(Math.log(index + 1) / Math.log(alphabet.length));
  const chars = [];
  for (let i = numChars - 1; i >= 0; i--) {
    const digit =
      (Math.floor(index / Math.pow(alphabet.length, i)) % alphabet.length) -
      (i > 0 ? 1 : 0);
    chars.push(alphabet[digit]);
  }
  return chars.join("");
};
const maxWidthSx = (maxWidth: number) => ({
  maxWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const PlanName = ({
  planName,
  defaultName,
  secondary = false,
  variant,
  maxWidth,
}: {
  planName: string | null;
  defaultName: string;
  variant: "h2" | "h5" | "body2";
  secondary?: boolean;
  maxWidth?: number;
}) => {
  const theme = useTheme();
  const sx = React.useMemo(() => {
    if (maxWidth && secondary) {
      return {
        ...maxWidthSx(maxWidth),
        color: theme.palette.text.secondary,
      };
    } else if (secondary) {
      return {
        color: theme.palette.text.secondary,
      };
    } else {
      return {};
    }
  }, [maxWidth, secondary, theme]);
  return (
    <Tooltip title={maxWidth ? planName ?? defaultName : undefined}>
      <Typography variant={variant} sx={sx}>
        {planName ?? defaultName}
      </Typography>
    </Tooltip>
  );
};

import React from "react";
import { Alert, Box, Button, Skeleton, Stack, Typography } from "@mui/material";

import { useTenant } from "hooks/settings";
import {
  DropWeight,
  FurnaceLoad,
  useGetActiveLoadDropWeightsAtQuery,
} from "src/store/api/generatedApi";

import { Bucket } from "./bucket";
import { useTenantTranslation } from "hooks/formatters";

const pollingIntervalMS = 10000;

type BodyProps = {
  loads: [FurnaceLoad, ...FurnaceLoad[]];
  dropWeights: DropWeight[];
};
const LoadingButton = () => (
  <Skeleton variant="rectangular">
    <Button sx={{ padding: 1, borderRadius: 2 }}>
      Load 10&nbsp;
      <Typography variant="h4" component="span">
        A4781
      </Typography>
    </Button>
  </Skeleton>
);

const LoadingBucket = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="3fr 2fr 2fr 4fr 4fr"
      sx={{ columnGap: 1 }}
    >
      <Box display="flex" gap={1} alignItems="flex-end" gridColumn="1/-1">
        <Skeleton variant="text">
          <Typography variant="h2">Bucket 1</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="subtitle1">C928A</Typography>
        </Skeleton>
      </Box>
      <Skeleton width="100%" height={60} sx={{ gridColumn: "1/-1" }} />
      {new Array(15).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={index} />
      ))}
    </Box>
  );
};

export const LoadsView = () => {
  const { current: atTimestamp } = React.useRef(new Date().toISOString());
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const { data } = useGetActiveLoadDropWeightsAtQuery(
    {
      tenantName,
      atTimestamp,
    },
    { pollingInterval: pollingIntervalMS }
  );
  if (data && data.loads.length > 0) {
    return (
      <Body
        dropWeights={data.drop_weights}
        loads={
          data.loads
            .slice(0)
            .sort(
              (a, b) =>
                new Date(b.start_time).getTime() -
                new Date(a.start_time).getTime()
            ) as [FurnaceLoad, ...FurnaceLoad[]]
        }
      />
    );
  } else if (data && !data.loads[0]) {
    return <Alert severity="warning">{t("nothingInProgress")}</Alert>;
  } else {
    return (
      <Stack gap={4} padding={4}>
        <Stack gap={2} direction="row">
          <LoadingButton />
          <LoadingButton />
          <LoadingButton />
          <LoadingButton />
        </Stack>
        <LoadingBucket />
        <LoadingBucket />
        <LoadingBucket />
      </Stack>
    );
  }
};

export const Body = ({ loads, dropWeights }: BodyProps) => {
  const [selectedLoad, setSelectedLoad] = React.useState(loads[0]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "12px 32px",
        height: "calc(100% - 24px)",
        overflow: "scroll",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        {loads.map((load) => {
          const sx = {
            padding: 1,
            borderRadius: 2,
          };
          const isSelected = selectedLoad.load_id === load.load_id;
          return (
            <Button
              key={load.load_id}
              variant={isSelected ? "contained" : "text"}
              sx={sx}
              onClick={() => setSelectedLoad(load)}
            >
              Load {load.load_id}&nbsp;
              <Typography variant="h4" component="span">
                {load.customer_charge_schedule_id}
              </Typography>
            </Button>
          );
        })}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        {new Array(selectedLoad.num_buckets).fill(null).map((_, index) => (
          <Bucket
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
            loadId={selectedLoad.load_id}
            dropWeights={dropWeights.filter(
              (dropWeight) =>
                dropWeight.customer_charge_schedule_bucket_number ===
                  index + 1 && dropWeight.load_id === selectedLoad.load_id
            )}
            rowHeight={40}
            headerHeight={40}
            bucketNumber={index + 1}
            customerChargeScheduleId={selectedLoad.customer_charge_schedule_id}
          />
        ))}
      </Box>
    </Box>
  );
};

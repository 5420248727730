import { Typography, useTheme } from "@mui/material";
import { SearchSummary } from "store/api/generatedApi";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";

type Props = {
  search: SearchSummary;
};

export const HistoryChildItem = ({ search }: Props) => {
  const formattedCreatedAt = useFormatLocalDateString("datetime")(
    search.created_at
  );
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      sx={{
        pl: 1,
        py: 0.5,
        borderLeft: 1,
        color: theme.palette.text.secondary,
      }}
    >
      {formattedCreatedAt}
      {" • "}
      {search.user_email}
    </Typography>
  );
};

import { Box, Button, Stack } from "@mui/material";
import { ExportPlan } from "./exportPlan/button";
import { useLocation } from "react-router";
import { OpenInPage } from "./openInPage";
import { usePermission } from "hooks/permissions";
import { useCanDeploy } from "hooks/useCanDeploy";
import { usePlanId } from "../../common/boundary/PlanId";
import { useCanDownloadExcel } from "hooks/useCanDownloadExcel";
import { CloudDownload } from "@mui/icons-material";
import { useDownloadPlanMixes } from "hooks/useDownloadPlanMixes";
import { useTenantTranslation } from "hooks/formatters";

export const PlanActionButtons = () => {
  const currentLocation = useLocation();
  const isPlanPeek = currentLocation.pathname.endsWith("/search");

  const { t } = useTenantTranslation();
  const planId = usePlanId();
  const canDeploy = useCanDeploy();
  const publisher = usePermission("publish");
  const canDownloadExcel = useCanDownloadExcel();

  const downloadPlanMixes = useDownloadPlanMixes(planId, null);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {isPlanPeek ? <OpenInPage /> : null}
      {canDownloadExcel ? (
        <Button startIcon={<CloudDownload />} onClick={downloadPlanMixes}>
          {t("download")}
        </Button>
      ) : null}

      {canDeploy && publisher ? (
        <Box>
          {/* Set the key, even though it's not strictly necessary, to ensure
            that the internal state of the export plan modal resets every time a
            new plan is loaded */}
          <ExportPlan key={planId} />
        </Box>
      ) : null}
    </Stack>
  );
};

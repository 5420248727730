import { Button, Stack, Typography } from "@mui/material";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenant } from "hooks/settings";
import { JsonViewer, SyncedJsonEditor } from "./syncedJsonEditor";
import { ValidatedTextField } from "../common/inputs/validatedTextField";
import { mapLoadedUnpack } from "models/loaded";
import { useTrackSearchIdInUrl } from "contexts/search/effects";
import { useSearch } from "contexts/search/provider";
import { useSearchId } from "hooks/search";
import { useNavigateToSearch } from "hooks/navigation";
import { ArrowBack } from "@mui/icons-material";
import { useTenantTranslation } from "hooks/formatters";
import { useHotkeys } from "react-hotkeys-hook";
import { hotkeys } from "hooks/hotkeys";
import { CopySearchButtonWrapper, PasteSearchButtonWrapper } from "./copyPaste";

export const DebugPage = () => {
  const { t } = useTenantTranslation();
  const tenant = useTenant();
  useTrackSearchIdInUrl();

  const searchId = useSearchId();
  const navigate = useNavigateToSearch();

  const goBack = () => {
    if (searchId !== null) {
      navigate(searchId);
    }
  };

  useHotkeys(hotkeys.debugPage, goBack);

  return (
    <Stack gap={2} sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Debug {tenant}</Typography>
        <Button startIcon={<ArrowBack />} onClick={goBack} color="secondary">
          {t("back")}
        </Button>
      </Stack>

      <SearchInput />
    </Stack>
  );
};

const SearchInput = () => {
  const serialiser = useNumberSerialiser({
    default: { value: null, text: "" },
  });

  const { parameters, context, results, clientSearchId, setSearchId } =
    useSearch();

  return (
    <>
      <ValidatedTextField
        value={clientSearchId}
        setValue={setSearchId}
        serialiser={serialiser}
        label="Search"
      />

      <Stack direction="row" gap={1}>
        <CopySearchButtonWrapper />
        <PasteSearchButtonWrapper />
      </Stack>

      <SyncedJsonEditor section="Parameters" state={parameters} />
      <JsonViewer section="Context" value={context} />

      <JsonViewer section="Search Result" value={results.results}>
        {results.isStale ? <Typography>Stale</Typography> : null}

        <Button
          variant="outlined"
          onClick={() => {
            const loadedSearchId = mapLoadedUnpack(
              parameters.server,
              ({ id }) => id
            );
            if (loadedSearchId != null) {
              results.run(loadedSearchId);
            }
          }}
        >
          Run
        </Button>
      </JsonViewer>
    </>
  );
};

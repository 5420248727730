import { Loaded, mapLoaded } from "models/loaded";
import { scaleToHeats } from "helpers";
import { backendApi } from "store/api/enhancedApi";
import { useTenant } from "hooks/settings";
import { getMicrosecondTimestamp } from "src/utils/getMicrosecondTimestamp";
import { v4 as UUID } from "uuid";
import { useMaterialsId, useObtainableBundles } from "../provider";
import { usePeriodIndex } from "hooks/periodIndex";

export type EstimateAvailabilityCallback = [
  Loaded<(heats: number) => Promise<void>>,
  { running: boolean },
];

export const useEstimateAvailability = (): EstimateAvailabilityCallback => {
  const tenant = useTenant();
  const materialsId = useMaterialsId();
  const periodTab = usePeriodIndex();
  const [, setAvailabilities] = useObtainableBundles(periodTab);
  const [estimate, { isLoading }] =
    backendApi.endpoints.getPerHeatObtainableEstimateFromTypicalConsumption.useLazyQuery();

  return [
    mapLoaded(materialsId, (id) => {
      return async (heats: number) =>
        estimate({ tenantName: tenant, materialMetadataSetId: id })
          .unwrap()
          .then((items) => {
            return setAvailabilities(() =>
              items.map(({ material_id, obtainable_max }) => ({
                uuid: UUID(),
                material_id,
                created_at: getMicrosecondTimestamp(),
                label: "",
                min_obtainable: null,
                max_obtainable: floor(scaleToHeats(obtainable_max, heats, 1)),
                specific_price: null,
                arrival_probability: null,
              }))
            );
          });
    }),
    { running: isLoading },
  ];
};

const floor = (value: number | null | undefined) =>
  value == null ? null : Math.floor(value);

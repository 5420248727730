import { Setter } from "hooks/state/syncing";
import { ExportPlanState } from "./state";
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import {
  useGetChargeScheduleMessageTemplateQuery,
  useSendChargeScheduleMessageMutation,
} from "store/api/generatedApi";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

export const SendSmsWrapper = ({
  state,
  setState,
}: {
  state: ExportPlanState;
  setState: Setter<ExportPlanState>;
}) => {
  const tenant = useTenant();
  const { t } = useTenantTranslation();
  const { data, isFetching, isError, isSuccess } =
    useGetChargeScheduleMessageTemplateQuery({
      tenantName: tenant,
      planId: state.plan.id,
      period: state.periodIndex,
    });

  if (isFetching) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <Alert severity="error">{t("errorExportingPlan")}</Alert>;
  }
  if (isSuccess) {
    return <SendSms setState={setState} messageTemplate={data.text} />;
  }
};

const SendSms = ({
  setState,
  messageTemplate,
}: {
  setState: Setter<ExportPlanState>;
  messageTemplate: string;
}) => {
  const { t } = useTenantTranslation();
  const tenant = useTenant();
  const theme = useTheme();

  const [send, { isLoading }] = useSendChargeScheduleMessageMutation();

  const [message, setMessage] = useState(messageTemplate);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
        }}
      >
        <Stack
          sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress />
          <Typography variant="h5" sx={{ mt: 2.5 }}>
            {t("sendingSms")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1, color: theme.palette.text.secondary }}
          >
            {t("doNotCloseThisWindow")}
          </Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box sx={{ width: "50%", maxWidth: 600 }}>
        <Typography>{t("message")}</Typography>
        <TextField
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          multiline
          fullWidth
          rows={12}
          inputProps={{ spellCheck: false }}
        />
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() =>
            send({
              tenantName: tenant,
              chargeScheduleMessage: { text: message },
            })
              .unwrap()
              .then(() =>
                setState((current) => ({
                  ...current,
                  stage: "sendSmsSuccess",
                }))
              )
              .catch(() =>
                setState((current) => ({
                  ...current,
                  stage: "sendSmsError",
                }))
              )
          }
          sx={{ mt: 2 }}
        >
          {t("sendSms")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

import {
  DataGridPremium,
  GridColDef,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-premium";
import { LinearProgress, Stack } from "@mui/material";

import {
  PlanConsumptionAggregation,
  useInventoryContent,
} from "./inventoryContent";
import {
  PlanTableDependencies,
  usePlanTableDependencies,
} from "../dependencies";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { InventoryColumnHeader } from "./InventoryColumnHeader";
import { TitleColumnHeader } from "../common/TitleColumnHeader";
import { InventoryRowHeader } from "./InventoryRowHeader";
import { InventoryCell } from "./InventoryCell";
import { ConsumptionMaterialValue, Row } from "./types";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { usePeriodIndex } from "hooks/periodIndex";

import type { JSX } from "react";

export const InventoryTable = ({ planId }: { planId: number }) => {
  const dependencies = usePlanTableDependencies(planId);

  return (
    <LoadedContent
      data={dependencies}
      waiting={<LinearProgress />}
      loading={<LinearProgress />}
    >
      {(loadedDependencies) => (
        <Stack gap={2} height="100%">
          <LoadedInventoryTable dependencies={loadedDependencies} />
        </Stack>
      )}
    </LoadedContent>
  );
};

const getConsumptionMaterialsMaximums = ({
  inventoryFinal,
  inventoryInitial,
  obtainableRemaining,
  obtainableRequired,
  consumption,
}: PlanConsumptionAggregation): ConsumptionMaterialValue => {
  return {
    inventoryFinal: Math.max(...Object.values(inventoryFinal.materials)),
    inventoryInitial: Math.max(...Object.values(inventoryInitial.materials)),
    obtainableRemaining: Math.max(
      ...Object.values(obtainableRemaining.materials)
    ),
    obtainableRequired: Math.max(
      ...Object.values(obtainableRequired.materials)
    ),
    consumption: Math.max(...Object.values(consumption.materials)),
  };
};

const LoadedInventoryTable = ({
  dependencies,
}: {
  dependencies: PlanTableDependencies;
}) => {
  const { t } = useTenantTranslation();
  const unit = useUnitsFormatter(true);
  const periodTab = usePeriodIndex();
  const consumption = useInventoryContent(
    dependencies.materials,
    dependencies.plan.summary.period_material_summary.filter(
      (summary) => summary.period === periodTab
    )
  );

  const scrapColumn: GridColDef<Row> = {
    field: "scrap",
    sortable: false,
    minWidth: 200,
    cellClassName: "group--header",
    display: "flex",
    renderHeader: () => <TitleColumnHeader title={t("scrap")} />,
    valueGetter: (_, { scrap }) => {
      const { type } = scrap;
      switch (type) {
        case "aggregation": {
          const { variant } = scrap;
          switch (variant) {
            case "mass":
              return `${t("totalMass")}${unit("mass")}`;
          }
          break;
        }
        case "material": {
          return scrap.material.name;
        }
      }
    },
    renderCell: ({ row: { scrap } }) => {
      const props = {
        ...scrap,
        period: periodTab,
      };
      return <InventoryRowHeader {...props} />;
    },
  };

  const inventoryKeys: (keyof PlanConsumptionAggregation)[] = [
    "inventoryInitial",
    "consumption",
    "inventoryFinal",
    "obtainableRequired",
    "obtainableRemaining",
  ];

  const inventoryColumns: GridColDef<Row>[] = inventoryKeys.map(
    (columnKey) => ({
      field: columnKey,
      renderHeader: () => {
        return (
          <InventoryColumnHeader
            name={columnKey}
            planConsumptionType={columnKey}
          />
        );
      },
      valueGetter: (_, { data }) => {
        const value = data[columnKey];
        if (value) {
          return value;
        } else {
          return null;
        }
      },
      renderCell: ({ row: { data, scrap, maximums } }): JSX.Element | null => {
        const value = data[columnKey];
        const maximum = maximums[columnKey];

        if (value != null && maximum != null) {
          switch (scrap.type) {
            case "material": {
              return (
                <InventoryCell
                  type="material"
                  value={value}
                  maximum={maximum}
                  aggregation={columnKey}
                />
              );
            }
            case "aggregation": {
              return (
                <InventoryCell
                  type="aggregation"
                  value={value}
                  variant={scrap.variant}
                />
              );
            }
          }
        } else {
          return null;
        }
      },
      flex: 1,
    })
  );

  const maximums = getConsumptionMaterialsMaximums(consumption);

  const rows: Row[] = dependencies.materials.byIndex.map((material, index) => {
    return {
      id: index,
      scrap: { type: "material", material },
      maximums,
      data: {
        inventoryInitial: consumption.inventoryInitial.materials[material.id],
        consumption: consumption.consumption.materials[material.id],
        inventoryFinal: consumption.inventoryFinal.materials[material.id],
        obtainableRequired:
          consumption.obtainableRequired.materials[material.id],
        obtainableRemaining:
          consumption.obtainableRemaining.materials[material.id],
      },
    };
  });

  const pinnedRows: GridPinnedRowsProp<Row> = {
    bottom: [
      {
        id: rows.length,
        maximums,
        scrap: { type: "aggregation", variant: "mass" },
        data: {
          inventoryInitial: consumption.inventoryInitial.total,
          consumption: consumption.consumption.total,
          inventoryFinal: consumption.inventoryFinal.total,
          obtainableRequired: consumption.obtainableRequired.total,
          obtainableRemaining: consumption.obtainableRemaining.total,
        },
      },
    ],
  };

  return (
    <DataGridPremium
      sx={{
        "& .MuiDataGrid-cell": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          width: "100%",
          whiteSpace: "normal",
        },
        "& .group--header": {
          py: 0.5,
          px: 1,
        },
      }}
      columns={[scrapColumn, ...inventoryColumns]}
      rows={rows}
      pinnedRows={pinnedRows}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};

import { Navigate } from "react-router";
import { useTenant } from "hooks/settings";

export const CraneDriverRedirect = () => {
  const tenant = useTenant();
  return <Navigate to={`/${tenant}/crane/driver`} />;
};

export const VersionedBucketsRedirect = () => {
  const tenant = useTenant();
  return <Navigate to={`/${tenant}/live/buckets`} />;
};

import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { Loaded } from "models/loaded";
import { ReactNode } from "react";

type Props<T> = {
  title: string;
  data: Loaded<T>;
  onClick: () => void;
  summarize: (data: T) => string;
  valid: (data: T) => boolean;
};

export const InputFieldItem = <T,>({
  title,
  data,
  summarize,
  valid,
  onClick,
}: Props<T>) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LoadedContent
          data={data}
          waiting={<Skeleton width={32} />}
          loading={<Skeleton width={32} />}
          disableFullWidth
        >
          {(loadedData) => (
            <Typography
              variant="body1Mono"
              sx={{
                color: valid(loadedData)
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
              }}
            >
              {summarize(loadedData)}
            </Typography>
          )}
        </LoadedContent>
        <IconButton
          onClick={onClick}
          size="small"
          sx={{
            ml: 1,
          }}
        >
          <CreateRoundedIcon sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export const InputFieldSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>{children}</Box>
    </Box>
  );
};

import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import { createAction, createReducer, nanoid } from "@reduxjs/toolkit";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { LoadedContent } from "components/common/loading/loadedContent";
import { useSearch } from "contexts/search/provider";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenant } from "hooks/settings";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import React from "react";
import {
  MaterialConstraintClass,
  useListMaterialConstraintClassesQuery,
} from "src/store/api/generatedApi";
import {
  addMaximumLimit,
  addMinimumLimit,
  addSoftMaximumLimit,
  addSoftMinimumLimit,
  Limits,
} from "src/utils/addNewLimit";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { formatMaterialLimits } from "../chips/materialLimit";
import { skipToken } from "@reduxjs/toolkit/query/react";

export type Id = number & { __id__: true };

export type Basket = {
  basketId: number;
  number: number;
  volume: number;
  limits: Limits;
};

export type MaterialLimit = {
  name: string;
  hardness: number;
  coefficients: Record<Id, number>;
  mixLimits: Limits;
  baskets: Record<Id, Basket>;
};

type Props = {
  materialLimit: MaterialLimit;
  onChange: (materialLimit: MaterialLimit) => void;
};

const initialVersion = nanoid();

const createUniqueAction = <T,>() => {
  return createAction<T>(nanoid());
};

const setBasketMinMass = createUniqueAction<[Id, number | null]>();
const setBasketMaxMass = createUniqueAction<[Id, number | null]>();
const setBasketSoftMinMass = createUniqueAction<[Id, number | null]>();
const setBasketSoftMaxMass = createUniqueAction<[Id, number | null]>();

const setMixMinMass = createUniqueAction<number | null>();
const setMixMaxMass = createUniqueAction<number | null>();
const setMixSoftMinMass = createUniqueAction<number | null>();
const setMixSoftMaxMass = createUniqueAction<number | null>();

const setName = createUniqueAction<string>();
const setHardness = createUniqueAction<number>();
const setCoefficient = createUniqueAction<[Id, number]>();
const clearCoefficient = createUniqueAction<Id>();

const createMaterialLimitReducer = (initialMaterialLimit: MaterialLimit) => {
  const versionedInitialState = {
    ...initialMaterialLimit,
    previousVersion: initialVersion,
    version: initialVersion,
  };
  return [
    createReducer(versionedInitialState, (builder) => {
      builder
        .addCase(setName, (state, { payload }) => {
          state.name = payload;
        })
        .addCase(setHardness, (state, { payload }) => {
          state.hardness = payload;
        })
        .addCase(
          setCoefficient,
          (state, { payload: [material_class_constraint_id, coefficient] }) => {
            state.coefficients[material_class_constraint_id] = coefficient;
          }
        )
        .addCase(clearCoefficient, (state, { payload }) => {
          delete state.coefficients[payload];
        })
        .addCase(setBasketMinMass, (state, { payload: [id, value] }) => {
          state.baskets[id]!.limits = addMinimumLimit(
            state.baskets[id]!.limits,
            value
          );
        })
        .addCase(setBasketSoftMinMass, (state, { payload: [id, value] }) => {
          state.baskets[id]!.limits = addSoftMinimumLimit(
            state.baskets[id]!.limits,
            value
          );
        })
        .addCase(setBasketSoftMaxMass, (state, { payload: [id, value] }) => {
          state.baskets[id]!.limits = addSoftMaximumLimit(
            state.baskets[id]!.limits,
            value
          );
        })
        .addCase(setBasketMaxMass, (state, { payload: [id, value] }) => {
          state.baskets[id]!.limits = addMaximumLimit(
            state.baskets[id]!.limits,
            value
          );
        })
        .addCase(setMixMinMass, (state, { payload }) => {
          state.mixLimits = addMinimumLimit(state.mixLimits, payload);
        })
        .addCase(setMixSoftMinMass, (state, { payload }) => {
          state.mixLimits = addSoftMinimumLimit(state.mixLimits, payload);
        })
        .addCase(setMixSoftMaxMass, (state, { payload }) => {
          state.mixLimits = addSoftMaximumLimit(state.mixLimits, payload);
        })
        .addCase(setMixMaxMass, (state, { payload }) => {
          state.mixLimits = addMaximumLimit(state.mixLimits, payload);
        })
        .addMatcher(
          () => true,
          (state) => {
            state.previousVersion = state.version;
            state.version = nanoid();
          }
        );
    }),
    versionedInitialState,
  ] as const;
};

export const MaterialLimitsEditor = ({
  onChange,
  materialLimit: initialMaterialLimit,
}: Props) => {
  const tenant = useTenant();
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);

  const [state, dispatch] = React.useReducer(
    ...createMaterialLimitReducer(initialMaterialLimit)
  );

  const {
    name,
    baskets,
    hardness,
    coefficients,
    mixLimits,
    version,
    previousVersion,
  } = state;

  React.useEffect(() => {
    const { version, previousVersion, ...materialLimit } = state;
    if (version !== previousVersion) {
      onChange(materialLimit);
    }
  }, [version, previousVersion, onChange, state]);

  const contextIds = mapLoadedUnpack(
    useSearch().context,
    ({ materialsId, materialPhysicsId }) => ({
      materialsId,
      materialPhysicsId,
    })
  );
  const classes = loadedEndpoint(
    useListMaterialConstraintClassesQuery(
      contextIds === null
        ? skipToken
        : {
            tenantName: tenant,
            materialMetadataSetId: contextIds.materialsId,
            materialPhysicsSetId: contextIds.materialPhysicsId,
          }
    )
  );

  const coefficientSerialiser = useNumberSerialiser({
    default: { value: null, text: "" },
    decimalPlaces: 3,
  });
  const limitSerialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
  });

  const createLabel = React.useCallback(
    (name: string, [minMass, maxMass]: [number | null, number | null]) => {
      return `${t(name)} ${formatMaterialLimits({ minMass, maxMass }, units)}`;
    },
    [units, formatMaterialLimits]
  );

  return (
    <Box width={700}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4">{t("name")}</Typography>
        <TextField
          value={name}
          onChange={(e) => dispatch(setName(e.currentTarget.value))}
          size="small"
        />
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          {t("bounds")}
        </Typography>

        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          {t("mix")}
          {name !== "" ? (
            <Chip
              sx={{ fontWeight: 500, ml: 1 }}
              label={createLabel(name, [mixLimits[0], mixLimits[3]])}
              size="small"
            />
          ) : null}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: 1,
          }}
        >
          <Typography>{t("min")}</Typography>
          <Typography>{t("softMin")}</Typography>
          <Typography>{t("softMax")}</Typography>
          <Typography>{t("max")}</Typography>
          <ValidatedTextField
            value={mixLimits[0]}
            setValue={(value) => dispatch(setMixMinMass(value))}
            serialiser={limitSerialiser}
          />
          <ValidatedTextField
            value={mixLimits[1]}
            setValue={(value) => dispatch(setMixSoftMinMass(value))}
            serialiser={limitSerialiser}
          />

          <ValidatedTextField
            value={mixLimits[2]}
            setValue={(value) => dispatch(setMixSoftMaxMass(value))}
            serialiser={limitSerialiser}
          />
          <ValidatedTextField
            value={mixLimits[3]}
            setValue={(value) => dispatch(setMixMaxMass(value))}
            serialiser={limitSerialiser}
          />
        </Box>
        {typeSafeObjectEntries(baskets).map(
          ([
            basketId,
            {
              number,
              limits: [minMass, softMinMass, softMaxMass, maxMass],
            },
          ]) => {
            return (
              <React.Fragment key={basketId}>
                <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                  {t("basket")} {number}
                  {name !== "" ? (
                    <Chip
                      sx={{ fontWeight: 500, ml: 1 }}
                      label={createLabel(name, [minMass, maxMass])}
                      size="small"
                    />
                  ) : null}
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    columnGap: 1,
                  }}
                >
                  <Typography>{t("min")}</Typography>
                  <Typography>{t("softMin")}</Typography>
                  <Typography>{t("softMax")}</Typography>
                  <Typography>{t("max")}</Typography>
                  <ValidatedTextField
                    value={minMass}
                    setValue={(value) =>
                      dispatch(setBasketMinMass([basketId, value]))
                    }
                    serialiser={limitSerialiser}
                  />
                  <ValidatedTextField
                    value={softMinMass}
                    setValue={(value) =>
                      dispatch(setBasketSoftMinMass([basketId, value]))
                    }
                    serialiser={limitSerialiser}
                  />

                  <ValidatedTextField
                    value={softMaxMass}
                    setValue={(value) =>
                      dispatch(setBasketSoftMaxMass([basketId, value]))
                    }
                    serialiser={limitSerialiser}
                  />
                  <ValidatedTextField
                    value={maxMass}
                    setValue={(value) =>
                      dispatch(setBasketMaxMass([basketId, value]))
                    }
                    serialiser={limitSerialiser}
                  />
                </Box>
              </React.Fragment>
            );
          }
        )}
        <Typography variant="h4" sx={{ gridColumn: "1/-1", marginTop: 2 }}>
          {t("hardness")}
        </Typography>
        <ValidatedTextField
          value={hardness}
          setValue={(value) => {
            if (value !== null) {
              dispatch(setHardness(value));
            }
          }}
          serialiser={coefficientSerialiser}
        />
      </Box>

      <Typography variant="h4" sx={{ mt: 2 }}>
        {t("coefficients")}
      </Typography>
      <LoadedContent data={classes}>
        {(loaded) => (
          <Stack gap={0.5}>
            {loaded.map((materialClass) => {
              const { id, name } = materialClass as MaterialConstraintClass & {
                id: Id;
              };
              return (
                <Stack key={id} direction="row" gap={2} alignItems="center">
                  <Typography sx={{ width: 150 }}>{name}</Typography>
                  <ValidatedTextField
                    value={coefficients[id] ?? null}
                    setValue={(value) => {
                      if (value !== null) {
                        dispatch(setCoefficient([id, value]));
                      } else {
                        dispatch(clearCoefficient(id));
                      }
                    }}
                    serialiser={coefficientSerialiser}
                  />
                </Stack>
              );
            })}
          </Stack>
        )}
      </LoadedContent>
    </Box>
  );
};

import React from "react";
import { useNavigate } from "react-router";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  DeploymentType,
  MaterialRead,
  NamedPlanMix,
  SteelGrade,
  useGetContextForPlanQuery,
  useGetMaterialMetadataSetQuery,
  useGetParametersForPlanQuery,
  useGetDeployedPlanMixesQuery,
  useUpdateAndDeployPlanMixesMutation,
  PlanMixUpdate,
  useCreateLoadMutation,
} from "src/store/api/generatedApi";

import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { sumRecords } from "src/utils/sumRecords";

import { TitledTabs } from "components/common/titledTabs";

import { Table } from "./Table";
import { areMixesEqual, getDRIMass } from "./utils";
import { LoadQueue } from "./LoadQueue";
import dayjs from "dayjs";

const getDRIMaterial = (mixes: NamedPlanMix[]): number | null => {
  return mixes[0]?.dri?.id ?? null;
};

type BodyProps = {
  materials: MaterialRead[];
  mixes: NamedPlanMix[];
  targetTappedMass: number;
  materialDecimalYields: Record<number, number>;
  steelGrades: Record<number, SteelGrade>;
  planId: number;
  startAt: Date;
  endAt: Date;
  deploymentType: DeploymentType;
  refetch: () => void;
};

type Props = {
  deploymentType: DeploymentType;
};

type PlanProps = {
  deploymentType: DeploymentType;
  id: number;
  mixes: NamedPlanMix[];
  startAt: Date;
  endAt: Date;
  refetch: () => void;
};

export const DataGridSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="200px repeat(25,1fr)"
      gridAutoRows="min-content"
      sx={{
        columnGap: 0.5,
        rowGap: 0.5,
        overflow: "hidden",
        height: "calc(100% - 24px)",
        margin: 1,
      }}
    >
      <Box sx={{ display: "flex", gridColumn: "1/-1", gap: 1 }}>
        <Skeleton variant="text">
          <Typography variant="h6">The first date in here</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="h6">The last date in here</Typography>
        </Skeleton>
        <Skeleton sx={{ ml: "auto" }}>
          <Button>Discard changes</Button>
        </Skeleton>
        <Skeleton>
          <Button>Save changes</Button>
        </Skeleton>
      </Box>
      {new Array(26).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={`${index}.column`} variant="rectangular" />
      ))}
      {new Array(26 * 20).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={40} key={`${index}.cell`} variant="rectangular" />
      ))}
    </Box>
  );
};

export const SupervisorView = ({ deploymentType }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTenantTranslation();

  const tabIndex = React.useMemo(() => {
    switch (deploymentType) {
      case "standard":
        return 0;
      case "backup":
        return 1;
    }
  }, [deploymentType]);

  const handleOnChange = React.useCallback(
    (index: number) => {
      switch (index) {
        case 0: {
          void navigate("../active");
          break;
        }
        case 1: {
          void navigate("../backup");
          break;
        }
      }
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        display: "grid",
        width: "100vw",
        gridTemplateColumns: "1fr 300px",
        gridTemplateRows: "min-content 1fr",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "calc(100% - 32px)",
          overflow: "hidden",
          gridRow: "1 / span 2",
          borderRight: "1px solid black",
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
          marginBottom: 1,
        }}
      >
        <TitledTabs
          tabIndex={tabIndex}
          onChange={handleOnChange}
          tabSpecs={[
            {
              title: t("active"),
              key: "active",
              content: <PlanWrapper deploymentType="standard" />,
            },
            {
              title: t("backup"),
              key: "backup",
              content: <PlanWrapper deploymentType="backup" />,
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          gridRow: "1 / span 2",
          minHeight: 1,
          height: "100%",
          overflow: "hidden",
          backgroundColor: theme.palette.grey[50],
          borderLeftWidth: 1,
          borderLeftColor: theme.palette.divider,
          borderLeftStyle: "solid",
        }}
      >
        <LoadQueue />
      </Box>
    </Box>
  );
};

const PlanWrapper = ({ deploymentType }: Props) => {
  const tenantName = useTenant();
  const {
    data: planMixes,
    refetch,
    isSuccess,
    isFetching,
  } = useGetDeployedPlanMixesQuery({
    tenantName,
    planId: "latest",
    period: 1,
    deploymentType,
  });

  if (!isFetching && isSuccess && planMixes) {
    const { plan_id, mixes, start_at, end_at } = planMixes;
    return (
      <Plan
        deploymentType={deploymentType}
        key={plan_id}
        id={plan_id}
        mixes={mixes}
        startAt={new Date(start_at)}
        endAt={new Date(end_at)}
        refetch={refetch}
      />
    );
  }
  return <DataGridSkeleton />;
};

export const Plan = ({
  id,
  mixes,
  startAt,
  endAt,
  deploymentType,
  refetch,
}: PlanProps) => {
  const tenantName = useTenant();
  const { data: planContext } = useGetContextForPlanQuery({
    tenantName,
    planId: id,
  });
  const { data: planParameters } = useGetParametersForPlanQuery({
    tenantName,
    planId: id,
  });
  const { data: materialMetadataSet } = useGetMaterialMetadataSetQuery({
    tenantName: tenantName,
    materialMetadataSetId: "default",
  });
  if (materialMetadataSet && planContext && planParameters) {
    return (
      <Body
        key={id}
        deploymentType={deploymentType}
        startAt={startAt}
        endAt={endAt}
        planId={id}
        mixes={mixes}
        materials={materialMetadataSet.materials.filter(
          (material) => material.visibility === "visible"
        )}
        materialDecimalYields={typeSafeObjectFromEntries(
          Object.values(planContext.material_physics).map((material) => [
            material.material_id,
            material.yield_percent / 100,
          ])
        )}
        targetTappedMass={
          planParameters.physical_parameters.target_tapped_mass_lower
        }
        steelGrades={typeSafeObjectFromEntries(
          planContext.steel_grades.map((steelGrade) => [
            steelGrade.id,
            steelGrade,
          ])
        )}
        refetch={refetch}
      />
    );
  } else {
    return <DataGridSkeleton />;
  }
};

const createPlanMixUpdates = (
  mixes: NamedPlanMix[],
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number
): Record<string, PlanMixUpdate> => {
  return mixes.reduce((mixesUpdate, mix) => {
    const {
      mix_number,
      steel_grade_ids,
      product_group_id,
      period,
      baskets,
      dri,
      number_of_heats,
      mix_id,
    } = mix;
    const newDRI =
      dri !== null
        ? {
            mass: Math.max(
              0,
              getDRIMass(
                Object.values(mix.baskets).reduce(
                  (acc, basket) => sumRecords(basket.material_masses, acc),
                  {}
                ),
                materialYieldsAsDecimal,
                targetTappedMass,
                materialYieldsAsDecimal[dri.id]!
              )
            ),
            id: dri.id,
          }
        : null;
    return {
      ...mixesUpdate,
      [mix_id]: {
        mix_number,
        steel_grade_ids,
        product_group_id,
        period,
        baskets,
        dri: newDRI,
        number_of_heats,
      },
    };
  }, {});
};

export const Body = ({
  deploymentType,
  mixes,
  materials,
  materialDecimalYields,
  targetTappedMass,
  steelGrades,
  planId,
  startAt,
  endAt,
  refetch,
}: BodyProps) => {
  const apiRef = useGridApiRef();

  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const theme = useTheme();

  const [revision, setRevision] = React.useState(0);
  const [editedMixes, setEditedMixes] = React.useState<null | NamedPlanMix[]>(
    null
  );

  const [doUpdateAndDeployPlanMixesMutation] =
    useUpdateAndDeployPlanMixesMutation();
  const [doCreateLoadMutation] = useCreateLoadMutation();

  const driMaterialId = React.useMemo(() => {
    return getDRIMaterial(mixes);
  }, [mixes]);

  const onHasEdited = (mixes: NamedPlanMix[]) => {
    setEditedMixes(mixes);
  };

  const handleOnClickSteelGrade = async ({
    mixId,
    steelGradeId,
  }: {
    mixId: number;
    steelGradeId: number;
  }) => {
    await doCreateLoadMutation({
      tenantName,
      loadCreate: {
        steel_grade_id: steelGradeId,
        plan_id: planId,
        mix_id: mixId,
      },
    });
  };

  const handleOnSubmit = async () => {
    if (editedMixes !== null) {
      void (await doUpdateAndDeployPlanMixesMutation({
        tenantName,
        planId,
        deployedPlanMixUpdates: {
          period: 1,
          mixes: createPlanMixUpdates(
            editedMixes,
            materialDecimalYields,
            targetTappedMass
          ),
          start_at: startAt.toISOString(),
          end_at: endAt.toISOString(),
          deployment_type: "standard",
        },
      }));
      refetch();
      setEditedMixes(null);
    }
  };

  const handleOnCancel = () => {
    setRevision((revision) => revision + 1);
    setEditedMixes(null);
  };

  const hasEdited =
    editedMixes !== null &&
    deploymentType === "standard" &&
    !areMixesEqual(mixes, editedMixes);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%", my: 1, alignItems: "center" }}>
        <Typography
          variant="h6"
          sx={{ color: theme.palette.text.secondary, fontWeight: "bold" }}
        >
          {dayjs(startAt).format("MMM, D YYYY HH:mm")} -&nbsp;
          {dayjs(endAt).format("MMM, D YYYY HH:mm")}
        </Typography>
        <Button
          disabled={!hasEdited}
          onClick={handleOnCancel}
          color="primary"
          variant="text"
          sx={{ mr: 1, ml: "auto" }}
        >
          {t("discardChanges")}
        </Button>
        <LoadingButton
          loading={false}
          disabled={!hasEdited}
          onClick={handleOnSubmit}
          color="primary"
          variant="contained"
        >
          {t("saveChanges")}
        </LoadingButton>
      </Box>
      <Table
        onSteelGradeClick={(mixId: number) => (steelGradeId: number) =>
          handleOnClickSteelGrade({ mixId, steelGradeId })
        }
        canEdit={deploymentType === "standard"}
        key={revision}
        mixes={mixes}
        driMaterialId={driMaterialId}
        materials={materials}
        materialDecimalYields={materialDecimalYields}
        targetTappedMass={targetTappedMass}
        steelGrades={steelGrades}
        apiRef={apiRef}
        onHasEdited={onHasEdited}
      />
    </>
  );
};

import React from "react";
import { AutoFixHigh } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import { Box, Button, LinearProgress } from "@mui/material";

import { loadedEndpoint, mapLoaded, mapLoadedUnpack } from "models/loaded";
import { liftLoadedState } from "models/loaded";
import { useTenantTranslation } from "hooks/formatters";

import { LoadedContent } from "src/components/common/loading/loadedContent";
import { ImportWizardLayout } from "src/components/common/importWizard/ImportWizard";
import {
  SearchSelector,
  SelectedSearch,
} from "components/search/SearchSelector";

import { InputModalContentLayout } from "../common/InputModalContentLayout";

import { useInventoryDependencies } from "./dependencies";
import { InventoryTable } from "./InventoryTable";
import { useSyncedParametersState } from "contexts/search/parameters";
import {
  useInventory,
  useMaterialPhysics,
  useMaterials,
} from "contexts/search/provider";
import {
  InventoryItem,
  useGetInventoryEstimateAtTimestampQuery,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { useCanEstimateInventoryAtTimestamp } from "hooks/useCanEstimateInventoryAtTimestamp";

enum Mode {
  Input,
  Search,
  Estimate,
}

const ImportFromSearchModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTenantTranslation();
  const [search, setSearch] = React.useState<SelectedSearch | null>(null);

  const [, setInventory] = useInventory();
  const materials = useMaterials();

  const inventory = mapLoaded(
    useSyncedParametersState(search?.search_id ?? null).client,
    ({ inventory }) => inventory
  );

  const handleConfirm = () => {
    const loadedInventory = mapLoadedUnpack(inventory, (i) => i);
    if (loadedInventory != null) {
      setInventory(() => loadedInventory);
    }
  };

  return (
    <ImportWizardLayout
      title={t("importFromSearch")}
      explorer={
        <SearchSelector selectedSearch={search} setSelectedSearch={setSearch} />
      }
      preview={
        <LoadedContent
          data={liftLoadedState({
            materials,
            materialPhysics: useMaterialPhysics(),
            inventory,
          })}
          waiting={null}
          loading={<LinearProgress />}
        >
          {({ materials, materialPhysics, inventory }) => (
            <InventoryTable
              materials={materials}
              materialPhysics={materialPhysics}
              inventory={inventory}
            />
          )}
        </LoadedContent>
      }
      close={onClose}
      onConfirm={handleConfirm}
    />
  );
};

const EstimateAtPlanStartModal = ({
  planStart,
  materialMetadataSetId,
  materialPhysicsSetId,
  onClose,
}: {
  planStart: string;
  materialMetadataSetId: number;
  materialPhysicsSetId: number;
  onClose: () => void;
}) => {
  const { t } = useTenantTranslation();

  const [currentInventory, setInventory] = useInventory();
  const materials = useMaterials();

  const inventory = mapLoaded(
    liftLoadedState({
      newInventory: loadedEndpoint(
        useGetInventoryEstimateAtTimestampQuery({
          tenantName: useTenant(),
          materialMetadataSetId: materialMetadataSetId,
          materialPhysicsSetId: materialPhysicsSetId,
          timestamp: planStart,
        })
      ),
      currentInventory,
    }),
    ({ newInventory }): InventoryItem[] => {
      return newInventory.map((item) => {
        return {
          material_id: item.material_id,
          specific_price: item.specific_price,
          quantity: item.quantity,
          previous_quantity: item.previous_quantity,
          deliveries_since_previous: item.deliveries_since_previous,
          consumption_since_previous: item.consumption_since_previous,
          projected_deliveries_since_previous:
            item.projected_deliveries_since_previous,
          projected_consumption_since_previous:
            item.projected_consumption_since_previous,
        };
      });
    }
  );

  const handleConfirm = () => {
    const loadedInventory = mapLoadedUnpack(inventory, (i) => i);
    if (loadedInventory != null) {
      setInventory(() => loadedInventory);
    }
  };

  return (
    <ImportWizardLayout
      title={`${t("estimateAtPlanStart")} (${planStart})`}
      preview={
        <LoadedContent
          data={liftLoadedState({
            materials,
            materialPhysics: useMaterialPhysics(),
            inventory,
          })}
          waiting={null}
          loading={<LinearProgress />}
        >
          {({ materials, materialPhysics, inventory }) => (
            <InventoryTable
              materials={materials}
              materialPhysics={materialPhysics}
              inventory={inventory}
              isFromPrevious
            />
          )}
        </LoadedContent>
      }
      close={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export const InventoryEditor = () => {
  const { t } = useTenantTranslation();
  const [mode, setMode] = React.useState(Mode.Input);
  const canEstimateInventoryAtTimestamp = useCanEstimateInventoryAtTimestamp();
  return (
    <LoadedContent data={useInventoryDependencies()}>
      {({ materials, materialPhysics, inventory, periods, searchContext }) => {
        return (
          <InputModalContentLayout
            leftPanel={
              <InventoryTable
                materials={materials}
                materialPhysics={materialPhysics}
                inventory={inventory}
              />
            }
            topPanel={
              <>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
                >
                  {canEstimateInventoryAtTimestamp ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setMode(Mode.Estimate)}
                      startIcon={<AutoFixHigh />}
                      disabled={periods?.[0]?.start_timestamp == undefined}
                    >
                      {t("estimateAtPlanStart")}
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setMode(Mode.Search)}
                    startIcon={<Search />}
                  >
                    {t("importFromSearch")}
                  </Button>
                </Box>
                {mode === Mode.Search && (
                  <ImportFromSearchModal onClose={() => setMode(Mode.Input)} />
                )}
                {mode === Mode.Estimate &&
                  periods?.[0]?.start_timestamp != null &&
                  searchContext !== undefined && (
                    <EstimateAtPlanStartModal
                      planStart={periods[0].start_timestamp}
                      materialMetadataSetId={searchContext.materialsId}
                      materialPhysicsSetId={searchContext.materialPhysicsId}
                      onClose={() => setMode(Mode.Input)}
                    />
                  )}
              </>
            }
            rightPanel={null}
            bottomPanel={null}
            removeLeftPanelOutline
          />
        );
      }}
    </LoadedContent>
  );
};

import { useTenantTranslation } from "src/hooks/formatters";
import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { mapLoadedUnpack } from "models/loaded";
import { useSearch } from "contexts/search/provider";
import {
  InventoryItem,
  ObtainableBundleItem,
  ProductGroupPlanItem,
  SteelGradePlanItem,
  SyncedSearchParameters,
} from "src/store/api/generatedApi";
import { CheckCircle } from "@mui/icons-material";

type RunConditions = {
  areRunConditionsMet: boolean;
  allScrapPriced: boolean;
  someObtainableScrap: boolean;
  someProduction: boolean;
};

export const useGetRunConditions = (): RunConditions | null =>
  mapLoadedUnpack(useSearch().parameters.client, getRunConditions);

export const getRunConditions = (
  parameters: SyncedSearchParameters
): RunConditions => {
  const allScrapPriced = !(
    anyUnpricedInventory(parameters.inventory) ||
    parameters.periods.some((period) =>
      anyUnpricedObtainable(period.obtainable_bundles)
    )
  );

  const someObtainableScrap =
    parameters.periods.some((period) =>
      anyObtainable(period.obtainable_bundles)
    ) || anyInventory(parameters.inventory);

  const someProduction = parameters.periods.some((period) =>
    anyHeats(
      period.production_plan.product_group_items ?? [],
      period.production_plan.steel_grade_items ?? []
    )
  );
  return {
    areRunConditionsMet:
      allScrapPriced && someObtainableScrap && someProduction,
    allScrapPriced,
    someObtainableScrap,
    someProduction,
  };
};

const anyHeats = (
  productGroupItems: ProductGroupPlanItem[],
  steelGradeItems: SteelGradePlanItem[]
) =>
  [...productGroupItems, ...steelGradeItems].some((item) => item.num_heats > 0);

const anyInventory = (inventory: InventoryItem[]) =>
  inventory.some(({ quantity }) => (quantity ?? 0) > 0);

const anyUnpricedInventory = (inventory: InventoryItem[]) =>
  inventory.some(
    ({ quantity, specific_price }) =>
      (quantity ?? 0) > 0 && specific_price == null
  );

const anyObtainable = (obtainableBundles: ObtainableBundleItem[]) =>
  obtainableBundles.some(
    ({ min_obtainable, max_obtainable }) =>
      (max_obtainable ?? min_obtainable ?? 0) > 0
  );

const anyUnpricedObtainable = (obtainableBundles: ObtainableBundleItem[]) =>
  obtainableBundles.some(
    ({ min_obtainable, max_obtainable, specific_price }) =>
      (max_obtainable ?? min_obtainable ?? 0) > 0 && specific_price == null
  );

export const RunConditions = () => {
  const { t } = useTenantTranslation();
  const runConditions = useGetRunConditions();
  if (!runConditions) return <LinearProgress />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 600,
          py: 2,
          px: 3,
          backgroundColor: "grey.50",
          borderRadius: 1,
        }}
      >
        <Typography>{t("runConditionDescriptionHeader")}</Typography>
        <RunConditionItem
          isComplete={runConditions.someProduction}
          text={t("runConditionDescriptionSomeProduction")}
        />
        <RunConditionItem
          isComplete={runConditions.someObtainableScrap}
          text={t("runConditionDescriptionSomeObtainableScrap")}
        />
        <RunConditionItem
          isComplete={runConditions.allScrapPriced}
          text={t("runConditionDescriptionNoUnpricedScrap")}
        />
      </Box>
    </Box>
  );
};

const RunConditionItem = ({
  isComplete,
  text,
}: {
  isComplete: boolean;
  text: string;
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", my: 1 }}>
      <CheckCircle
        sx={{
          color: isComplete
            ? theme.palette.success.main
            : theme.palette.grey[200],
          mr: 1,
        }}
      />
      <Typography
        sx={{
          color: isComplete
            ? theme.palette.text.primary
            : theme.palette.text.disabled,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

import { DropWeight } from "src/store/api/generatedApi";

export type NamedDropWeight = Omit<DropWeight, "material_id"> & {
  customer_material_name: string;
  material_id: number;
};

type ReturnType = [NamedDropWeight[], number, number[]];

export const splitToNamedDropWeights = (
  dropWeights: DropWeight[],
  materialIdLevel2NameMapping: Record<number, string>
): ReturnType => {
  const [namedDropWeights, unknownMaterialCount, missingMaterialIds] =
    dropWeights.reduce(
      (
        [namedDropWeights, unknownMaterialCount, missingMaterialIds],
        dropWeight
      ) => {
        if (!dropWeight.material_id) {
          return [
            namedDropWeights,
            unknownMaterialCount + 1,
            missingMaterialIds,
          ];
        } else if (!materialIdLevel2NameMapping[dropWeight.material_id]) {
          return [
            namedDropWeights,
            unknownMaterialCount,
            [...missingMaterialIds, dropWeight.material_id],
          ];
        } else {
          return [
            [
              ...namedDropWeights,
              {
                ...dropWeight,
                material_id: dropWeight.material_id,
                customer_material_name:
                  materialIdLevel2NameMapping[dropWeight.material_id]!,
              },
            ],
            unknownMaterialCount,
            missingMaterialIds,
          ];
        }
      },
      [[], 0, []] as ReturnType
    );

  return [namedDropWeights, unknownMaterialCount, missingMaterialIds];
};

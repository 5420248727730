import { Stack, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  label: string;
  value?: string | ReactNode;
  password?: boolean;
};

export const ProfileSettingsFormItem = ({ label, value, password }: Props) => {
  const theme = useTheme();
  return (
    <Stack>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          {password ? "•".repeat(10) : value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

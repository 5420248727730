import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  Measure,
  useGroupOption,
  useGrouping,
  useGroups,
  useMeasureOption,
  useMixes,
  useSteelGradesOption,
} from "./ProductionContext";
import { PlanGroup, PlanMix } from "./productionContent";
import { MaterialRead, ProductGroup } from "src/store/api/generatedApi";
import {
  DataGridPremium,
  GridColDef,
  GridPinnedColumnFields,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-premium";
import { TitleColumnHeader } from "../common/TitleColumnHeader";
import { ScrapRowHeader } from "./ScrapRowHeader";
import { AggregationCell, VisualisedCell } from "./ProductionCell";
import { HeatColumnHeader } from "./HeatColumnHeader";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

import type { JSX } from "react";

type RowData = Record<number, Record<number, PlanMix & { id: number }>>;

type AggregationRowId =
  | "total_mass"
  | "total_price"
  | "materials_price"
  | "electrical_energy_price";

type ScrapRow = {
  id: number | AggregationRowId;
  scrap: MaterialRead;
  data: RowData;
};

type Props = {
  materials: MaterialRead[];
  sx: SxProps<Theme>;
};

const useLayout = (materials: MaterialRead[]) => {
  const measureOption = useMeasureOption();
  const groups = useGroups();
  const mixes = useMixes();
  const grouping = useGrouping();
  const steelGrades = useSteelGradesOption();
  const groupOption = useGroupOption();
  const { t } = useTenantTranslation();
  const unit = useUnitsFormatter(true);

  const groupings: [ProductGroup, PlanGroup][] = groups
    .map((group): [ProductGroup, PlanGroup] => [
      grouping.byId[group.group]!,
      group,
    ])
    .filter(
      ([grouping]) =>
        steelGrades.size === 0 ||
        grouping.steel_grades.some(({ name }) => steelGrades.has(name))
    );

  const scrapColumn: GridColDef<ScrapRow> = {
    field: "scrap",
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <TitleColumnHeader title={t("scrap")} />,
    headerClassName: "group--header",
    cellClassName: "group--header",
    valueGetter: (_, { scrap, id }) => {
      switch (id) {
        case "total_mass":
          return `${t("totalMass")}${unit("mass")}`;
        case "total_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        case "materials_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        case "electrical_energy_price":
          return `${t("totalPrice")}${unit("cost")}}`;
        default: {
          return scrap.name;
        }
      }
    },
    renderCell: ({
      row: {
        scrap: { name, id: material_id },
        id,
      },
    }) => {
      return <ScrapRowHeader rowId={id} id={material_id} name={name} />;
    },
  };

  const groupColumns: GridColDef<ScrapRow>[] = groupings.map(
    ([{ id, name }, { heats, mix, multipleMixes, group }], index) => {
      return {
        field: "group_" + index.toString(),
        sortable: false,
        minWidth: 132,
        headerClassName: "group--header",

        valueGetter: (_, { data }) => {
          if (id !== null && id !== undefined && data[id]?.[mix]) {
            const { id: materialId, materials, total } = data[id]![mix]!;
            const value = materials[materialId];
            if (value) {
              switch (measureOption) {
                case Measure.Percentage: {
                  return value / total;
                }
                case Measure.Mass: {
                  return value;
                }
              }
            } else {
              return null;
            }
          }
        },
        renderCell: ({ row: { data, id: rowId } }): JSX.Element | null => {
          if (id != null && data[id]?.[mix]) {
            const {
              materials,
              total,
              id: materialId,
              maximum,
              total_price,
              materials_price,
              electrical_energy_price,
            } = data[id]![mix]!;

            switch (rowId) {
              case "total_mass": {
                return <AggregationCell value={total} variant="mass" />;
              }
              case "total_price": {
                return <AggregationCell value={total_price} variant="price" />;
              }
              case "materials_price": {
                return (
                  <AggregationCell value={materials_price} variant="price" />
                );
              }
              case "electrical_energy_price": {
                return (
                  <AggregationCell
                    value={electrical_energy_price}
                    variant="price"
                  />
                );
              }
              default: {
                const value = materials[materialId];
                return value == null || value === 0.0 ? null : (
                  <VisualisedCell
                    value={value}
                    total={total}
                    maximum={maximum}
                  />
                );
              }
            }
          } else {
            return null;
          }
        },
        renderHeader: () => {
          return (
            <HeatColumnHeader
              heats={heats}
              mix={mix}
              multipleMixes={multipleMixes}
              name={name}
              group={group}
              groupOption={groupOption}
            />
          );
        },
      };
    }
  );

  const rows: ScrapRow[] = materials.map((material, index) => {
    const data: RowData = groupings.reduce<RowData>((row, [, group]) => {
      if (!row[group.group]) {
        row[group.group] = {};
      }

      if (!row[group.group]![group.mix]) {
        row[group.group]![group.mix] = {
          ...mixes[group.group]![group.mix]!,
          id: material.id,
        };
        return row;
      }

      row[group.group]![group.mix] = {
        ...mixes[group.group]![group.mix]!,
        id: material.id,
      };

      return row;
    }, {});
    return {
      id: index,
      scrap: material,
      data,
    };
  });

  const columns: GridColDef<ScrapRow>[] = [scrapColumn, ...groupColumns];

  // Only show the price breakdown if the electrical energy price is nonzero for at least one mix
  const splitPriceIntoComponents =
    rows.filter(
      (row) =>
        Object.values(row.data).filter(
          (groupMixes) =>
            Object.values(groupMixes).filter(
              (mix) => mix.electrical_energy_price > 0
            ).length > 0
        ).length > 0
    ).length > 0;

  const pinnedRows: GridPinnedRowsProp<ScrapRow> = {
    bottom: rows[0]
      ? [
          {
            id: "total_mass",
            scrap: rows[0].scrap,
            data: rows[0].data,
          },
          ...(splitPriceIntoComponents
            ? [
                {
                  id: "materials_price" as AggregationRowId,
                  scrap: rows[0].scrap,
                  data: rows[0].data,
                },
                {
                  id: "electrical_energy_price" as AggregationRowId,
                  scrap: rows[0].scrap,
                  data: rows[0].data,
                },
              ]
            : []),
          {
            id: "total_price",
            scrap: rows[0].scrap,
            data: rows[0].data,
          },
        ]
      : [],
  };

  const pinnedColumns: GridPinnedColumnFields = {
    left: ["scrap"],
  };

  return {
    rows,
    columns,
    pinnedColumns,
    pinnedRows,
    rowHeight: 32,
    columnHeaderHeight: 70,
  };
};

export const HeatXScrapYTable = ({ materials, sx }: Props) => {
  const {
    pinnedColumns,
    pinnedRows,
    columns,
    rows,
    columnHeaderHeight,
    rowHeight,
  } = useLayout(materials);

  return (
    <DataGridPremium
      sx={sx}
      pinnedColumns={pinnedColumns}
      columns={columns}
      pinnedRows={pinnedRows}
      rows={rows}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};

import React from "react";
import { Chip, IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridApi,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { z } from "zod";

import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  MaterialRead,
  NamedPlanMix,
  SteelGrade,
} from "src/store/api/generatedApi";
import { sumRecords } from "src/utils/sumRecords";
import { getDRIMass, BasketNumberSchema } from "./utils";

type BasketNumber = z.infer<typeof BasketNumberSchema>;

type TotalRow = {
  id: number;
  type: "total";
  steelGrades: number[];
  driMaterialId: number | null;
  mixId: number;
  copperGroup: number;
};

type BasketRow = {
  id: number;
  type: "basket";
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  materialMasses: Record<number, number>;
  driMaterialId: number | null;
  mixId: number;
  basketNumber: number;
};

type EmptyBasketRow = {
  id: number;
  type: "empty_basket";
  mixId: number;
  mixName: string;
  copperGroup: number;
  steelGrades: number[];
  basketNumber: BasketNumber;
  driMaterialId: number | null;
};

type ProfileRow = {
  id: number;
  type: "profile";
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  materialMasses: Record<number, number>;
  driMaterialId: number | null;
  mixId: number;
  mix: NamedPlanMix;
};

export type Row = ProfileRow | BasketRow | TotalRow | EmptyBasketRow;

type SteelGradesCellProps = {
  steelGrades: SteelGrade[];
  onClick: (id: number) => void;
};

type Props = {
  canEdit: boolean;
  mixes: NamedPlanMix[];
  materials: MaterialRead[];
  driMaterialId: number | null;
  materialDecimalYields: Record<number, number>;
  targetTappedMass: number;
  steelGrades: Record<number, SteelGrade>;
  apiRef: React.RefObject<GridApiPremium>;
  onSteelGradeClick: (midId: number) => (id: number) => void;
  onHasEdited: (mixes: NamedPlanMix[]) => void;
};

type EmptyBasketWeightCellProps = {
  onSubmit: () => void;
  id: number;
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  driMaterialId: number | null;
  mixId: number;
  basketNumber: BasketNumber;
};

type BasketWeightCellProps = {
  onSubmit: () => void;
  id: number;
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  driMaterialId: number | null;
  mixId: number;
  basketNumber: BasketNumber;
  value: number | null;
};

const SteelGradesCell = ({ steelGrades, onClick }: SteelGradesCellProps) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 0.4,
        flexWrap: "wrap",
        paddingX: 0.5,
        paddingY: 0.5,
        overflow: "scroll",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      {steelGrades.map((steelGrade) => (
        <Chip
          key={steelGrade.id}
          label={steelGrade.name}
          size="small"
          onClick={() => onClick(steelGrade.id)}
          sx={{
            cursor: "pointer",
          }}
        />
      ))}
    </Stack>
  );
};

const EmptyBasketWeightCell = ({
  onSubmit,
  id,
  copperGroup,
  mixName,
  steelGrades,
  driMaterialId,
  mixId,
  basketNumber,
}: EmptyBasketWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const basketRow: BasketRow = {
      id,
      type: "basket",
      copperGroup,
      mixName,
      steelGrades,
      driMaterialId,
      mixId,
      basketNumber,
      materialMasses: {},
    };
    apiRef.current.updateRows([basketRow]);
    onSubmit();
  };
  return (
    <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
      <AddCircleOutline fontSize="small" />
    </IconButton>
  );
};

const BasketWeightCell = ({
  onSubmit,
  id,
  copperGroup,
  mixName,
  steelGrades,
  driMaterialId,
  mixId,
  basketNumber,
  value,
}: BasketWeightCellProps) => {
  const apiRef = useGridApiContext();
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const emptyBasketRow: EmptyBasketRow = {
      id,
      type: "empty_basket",
      copperGroup,
      mixName,
      steelGrades,
      driMaterialId,
      mixId,
      basketNumber,
    };
    apiRef.current.updateRows([emptyBasketRow]);
    onSubmit();
  };
  return (
    <>
      <Typography variant="body1Mono">{value}</Typography>
      <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
        <RemoveCircleOutline fontSize="small" />
      </IconButton>
    </>
  );
};

const useMakeColumns = (
  materials: MaterialRead[],
  originalRows: Record<number, Row>,
  materialYields: Record<number, number>,
  targetTappedMass: number,
  steelGrades: Record<number, SteelGrade>,
  canEdit: boolean,
  onSteelGradeClick: (mixId: number) => (id: number) => void,
  onRowChange: () => void
): GridColDef<Row>[] => {
  const { t } = useTenantTranslation();
  const bracketUnits = useUnitsFormatter(true);
  const columns = React.useMemo((): GridColDef<Row>[] => {
    const columns: GridColDef<Row>[] = [
      {
        field: "mixName",
        width: 80,
        headerName: "#",
        valueGetter: (_, row): number => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "profile": {
              return row.mixId;
            }
          }
        },
        cellClassName: "grey-50 bottom-row center",
        headerClassName: "grey-50",
      },
      {
        field: "steel_grades",
        headerName: t("steelGrades"),
        width: 350,
        valueGetter: (_, row): number[] | string => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "profile": {
              return row.steelGrades;
            }
          }
        },
        renderCell: ({ row }) => {
          switch (row.type) {
            case "basket":
            case "empty_basket":
            case "profile": {
              return (
                <SteelGradesCell
                  steelGrades={row.steelGrades.map(
                    (steelGradeId) => steelGrades[steelGradeId]!
                  )}
                  onClick={onSteelGradeClick(row.mixId)}
                />
              );
            }
            case "total":
              return null;
          }
        },
        cellClassName: "grey-50 bottom-row",
        headerClassName: "grey-50",
      },
      {
        field: "copper_group",
        headerName: "Cu",
        width: 50,
        headerClassName: "warning-light",
        valueGetter: (_, row): string => {
          switch (row.type) {
            case "basket":
            case "total":
            case "empty_basket":
            case "profile": {
              return `${row.mixId}.${row.copperGroup}`;
            }
          }
        },
        valueFormatter: (value: string) => {
          const copperGroup = Number(value.split(".").slice(1).join("."));
          return (copperGroup * 100).toFixed(0);
        },
        cellClassName: "warning-light bottom-row center",
      },
      {
        field: "dri_weight",
        headerName: `${t("dri")} ${bracketUnits("mass")}`,
        width: 50,
        headerClassName: "grey-200",
        valueGetter: (_, row, __, dataGridApi): number | null => {
          switch (row.type) {
            case "basket":
            case "profile":
            case "empty_basket":
            case "total": {
              if ("getAllRowIds" in dataGridApi.current && row.driMaterialId) {
                const { driMaterialId } = row;
                const amount = getDRIMass(
                  dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter(
                      (
                        dataGridRow: Row | null
                      ): dataGridRow is ProfileRow | BasketRow =>
                        dataGridRow !== null &&
                        row.mixId === dataGridRow.mixId &&
                        (dataGridRow.type === "basket" ||
                          dataGridRow.type === "profile")
                    )
                    .map((row) => row.materialMasses)
                    .reduce(
                      (total, materialMasses) =>
                        sumRecords(total, materialMasses),
                      {}
                    ),
                  materialYields,
                  targetTappedMass,
                  /* 
                    driMaterialIds should be a single id,
                    this needs to change in a subsequent PR
                    for the whole PlanMixes class in the backend
                  */
                  materialYields[driMaterialId]!
                );
                return amount;
              }
              return null;
            }
          }
        },
        valueFormatter: (value) => {
          if (typeof value === "number") {
            return Number(value).toFixed(0);
          } else {
            return "";
          }
        },
        cellClassName: "grey-200 bottom-row center",
      },
      {
        field: "basket_weight",
        resizable: false,
        headerName: `${t("basket")} ${bracketUnits("mass")}`,
        width: 100,
        headerClassName: "grey-200 edge-column",
        renderCell: (
          rowParams: GridRenderCellParams<Row, number | null>
        ): React.JSX.Element | number | null => {
          const { row, value } = rowParams;
          switch (row.type) {
            case "empty_basket": {
              if (canEdit) {
                const {
                  copperGroup,
                  mixName,
                  steelGrades,
                  driMaterialId,
                  mixId,
                  basketNumber,
                  id,
                } = row;
                return (
                  <EmptyBasketWeightCell
                    onSubmit={onRowChange}
                    id={id}
                    copperGroup={copperGroup}
                    mixName={mixName}
                    steelGrades={steelGrades}
                    driMaterialId={driMaterialId}
                    mixId={mixId}
                    basketNumber={basketNumber}
                  />
                );
              } else {
                return null;
              }
            }
            case "total": {
              if (value) {
                return value;
              }
              return null;
            }
            case "basket":
            case "profile": {
              if (canEdit) {
                const {
                  id,
                  mixId,
                  mixName,
                  copperGroup,
                  steelGrades,
                  driMaterialId,
                  type,
                } = row;
                const basketNumber = BasketNumberSchema.parse(
                  type === "profile" ? 1 : row.basketNumber
                );
                return (
                  <BasketWeightCell
                    id={id}
                    mixId={mixId}
                    mixName={mixName}
                    copperGroup={copperGroup}
                    steelGrades={steelGrades}
                    driMaterialId={driMaterialId}
                    basketNumber={basketNumber}
                    onSubmit={onRowChange}
                    value={value ?? null}
                  />
                );
              } else {
                return value ?? null;
              }
            }
          }
        },
        valueGetter: (_, row, __, dataGridApi) => {
          switch (row.type) {
            case "basket":
            case "profile":
              return Object.values(row.materialMasses).reduce(
                (total, mass) => total + mass,
                0
              );
            case "total":
              return Object.values(
                dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter(
                    (row: Row | null): row is ProfileRow | BasketRow =>
                      row !== null &&
                      row.type !== "total" &&
                      row.type !== "empty_basket"
                  )
                  .filter(({ mixId }) => mixId === row.mixId)
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )
              ).reduce((sum, mass) => sum + mass, 0);
          }
        },
        valueFormatter: (value: number) => {
          if (value !== undefined) {
            return value.toFixed(0);
          }
        },
        rowSpanValueGetter: () => null,
        cellClassName: (row) => {
          const baseClass = "grey-200 edge-column";
          const {
            row: { type },
          } = row;
          switch (type) {
            case "empty_basket": {
              return baseClass + " flex-start";
            }
            case "total": {
              return baseClass + " bottom-row align-center";
            }
            case "basket":
            case "profile":
              return baseClass + " space-between";
          }
        },
      },
      ...materials.map(
        (material): GridColDef<Row> => ({
          field: material.id.toString(),
          headerName: material.name,
          width: 50,
          type: "number",
          valueGetter: (_, row, __, dataGridApi) => {
            switch (row.type) {
              case "basket":
              case "profile":
                return row.materialMasses[material.id] &&
                  row.materialMasses[material.id] !== 0
                  ? row.materialMasses[material.id]
                  : null;
              case "total": {
                const value = dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter((row: Row | null): row is Row => row !== null)
                  .filter(
                    (
                      dataGridRow: Row
                    ): dataGridRow is ProfileRow | BasketRow => {
                      switch (dataGridRow.type) {
                        case "basket":
                        case "profile": {
                          return row.mixId === dataGridRow.mixId;
                        }
                        case "total": {
                          return false;
                        }
                      }
                      // This needs to be here to make eslint happy. Not sure why.
                      return false;
                    }
                  )
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )[material.id];
                return value ? value : null;
              }
            }
          },
          valueFormatter: (value: unknown) => {
            if (typeof value === "number") {
              return `${value.toFixed(0)}`;
            }
          },
          rowSpanValueGetter: () => null,
          editable: canEdit,
          valueSetter: (value, row) => {
            switch (row.type) {
              case "empty_basket":
              case "total": {
                return row;
              }
              case "profile":
              case "basket":
                if (value !== undefined && value !== null && value >= 0) {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: Number(value),
                    },
                  };
                } else {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: 0,
                    },
                  };
                }
            }
          },
          cellClassName: ({ row }) => {
            const { type } = row;
            switch (type) {
              case "total": {
                return "grey-50 bottom-row center";
              }
              case "empty_basket":
                return "";
              case "basket":
              case "profile": {
                const { materialMasses, id } = row;
                const original = originalRows[id]!;
                if (
                  original.type === "basket" ||
                  (original.type === "profile" &&
                    original.materialMasses[material.id] !==
                      materialMasses[material.id])
                ) {
                  return "edited center";
                } else if (original.type === "empty_basket") {
                  return "edited center";
                } else {
                  return "center";
                }
              }
            }
          },
        })
      ),
    ];

    return columns.map((column) => ({
      ...column,
      sortable: false,
      headerAlign: "center",
    }));
  }, [t, materials]);
  return columns;
};

const makeBasketRow =
  (basketNumber: Exclude<BasketNumber, 1>) =>
  (mix: NamedPlanMix): Omit<EmptyBasketRow, "id"> | Omit<BasketRow, "id"> => {
    const {
      baskets,
      dri,
      steel_grade_ids,
      copper_percent_max_tolerance,
      mix_name,
      mix_id,
    } = mix;
    if (baskets[basketNumber]) {
      const { material_masses } = baskets[basketNumber]!;
      return {
        type: "basket",
        driMaterialId: dri?.id ?? null,
        steelGrades: steel_grade_ids,
        materialMasses: material_masses,
        copperGroup: copper_percent_max_tolerance,
        mixName: mix_name,
        mixId: mix_id,
        basketNumber: Number(basketNumber),
      };
    } else {
      return {
        type: "empty_basket",
        mixId: mix_id,
        driMaterialId: dri ? dri.id : null,
        copperGroup: copper_percent_max_tolerance,
        steelGrades: steel_grade_ids,
        mixName: mix_name,
        basketNumber,
      };
    }
  };

const makeBasketRow2 = makeBasketRow(2);
const makeBasketRow3 = makeBasketRow(3);

const makeProfileRow = (mix: NamedPlanMix): Omit<ProfileRow, "id"> => {
  const materialMasses = mix.baskets[1] ? mix.baskets[1].material_masses : {};
  return {
    type: "profile",
    driMaterialId: mix.dri?.id ?? null,
    copperGroup: mix.copper_percent_max_tolerance,
    mixName: mix.mix_name,
    steelGrades: mix.steel_grade_ids,
    materialMasses,
    mixId: mix.mix_id,
    mix,
  };
};

const makeTotalRow = (mix: NamedPlanMix): Omit<TotalRow, "id"> => {
  return {
    type: "total",
    steelGrades: mix.steel_grade_ids,
    copperGroup: mix.copper_percent_max_tolerance,
    mixId: mix.mix_id,
    driMaterialId: mix.dri?.id ?? null,
  };
};

const makeRows = (mixes: NamedPlanMix[]): Row[] => {
  return Object.values(mixes)
    .flatMap((mix) => {
      return [
        makeProfileRow(mix),
        makeBasketRow2(mix),
        makeBasketRow3(mix),
        makeTotalRow(mix),
      ];
    })
    .map((row, index) => ({
      ...row,
      id: index,
    }));
};

const makeMixes = (rows: Row[]): NamedPlanMix[] => {
  const basketRows = rows.filter(
    (row: Row): row is BasketRow => row.type === "basket"
  );
  const profileRows = rows.filter(
    (row: Row): row is ProfileRow => row.type === "profile"
  );
  return profileRows.map((profileRow) => {
    return {
      ...profileRow.mix,
      baskets: {
        1: {
          material_masses: profileRow.materialMasses,
          total_mass: Object.values(profileRow.materialMasses).reduce(
            (acc, mass) => acc + mass,
            0
          ),
        },

        ...basketRows
          .filter((basketRow) => basketRow.mixId === profileRow.mixId)
          .reduce((baskets, basketRow) => {
            return {
              ...baskets,
              [basketRow.basketNumber]: {
                material_masses: basketRow.materialMasses,
                total_mass: Object.values(basketRow.materialMasses).reduce(
                  (acc, mass) => acc + mass,
                  0
                ),
              },
            };
          }, {}),
      },
    };
  });
};

const getAllRows = (apiRef: React.MutableRefObject<GridApi>) => {
  return apiRef.current
    .getAllRowIds()
    .map((rowId) => apiRef.current.getRow<Row>(rowId))
    .filter((row: Row | null): row is Row => row !== null);
};

export const Table = ({
  canEdit,
  mixes,
  materials,
  materialDecimalYields,
  targetTappedMass,
  driMaterialId,
  steelGrades,
  apiRef,
  onHasEdited,
  onSteelGradeClick,
}: Props) => {
  const theme = useTheme();

  const rows = React.useMemo(() => makeRows(mixes), [mixes]);

  const onRowChange = () => {
    const newMixes = makeMixes(getAllRows(apiRef));
    onHasEdited(newMixes);
  };

  const columns = useMakeColumns(
    materials.filter((material) => driMaterialId !== material.id),
    rows,
    materialDecimalYields,
    targetTappedMass,
    steelGrades,
    canEdit,
    onSteelGradeClick,
    onRowChange
  );

  return (
    <DataGridPremium<Row>
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      columnHeaderHeight={40}
      processRowUpdate={(newRow) => {
        /* 
            handleUpdateRow needs to happen AFTER this callback has returned the new row.
            By setting a timeout we can put its call to the the queue behind the 
            processRowUpdate prop callback's promise
          */
        setTimeout(() => onRowChange(), 0);
        return newRow;
      }}
      unstable_rowSpanning
      isCellEditable={(params: GridCellParams<Row>) =>
        params.row.type !== "total"
      }
      sx={{
        width: "calc(100%)",
        [".align-center"]: {
          alignContent: "center",
        },
        [".space-between"]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        [".flex-start"]: {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        [".center"]: {
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
        },
        [".warning-light"]: {
          backgroundColor: theme.palette.warning.light,
        },
        [".grey-200"]: {
          backgroundColor: theme.palette.grey[200],
        },
        [".grey-50"]: {
          backgroundColor: theme.palette.grey[50],
        },
        [".bottom-row"]: {
          borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        },
        [".edge-column"]: {
          borderRight: `1px solid ${theme.palette.secondary.dark}`,
        },
        [".MuiDataGrid-columnHeader"]: {
          textAlign: "center",
          padding: 0,
          borderBottom: `1px solid ${theme.palette.secondary.dark} !important`,
        },
        [".MuiDataGrid-columnHeaderTitle"]: {
          fontSize: theme.typography.body1.fontSize,
        },
        [".MuiDataGrid-cell"]: {
          fontFamily: theme.typography.body1Mono,
        },
        ["input::-webkit-outer-spin-button, input::-webkit-inner-spin-button"]:
          {
            "-webkit-appearance": "none",
          },
      }}
    />
  );
};

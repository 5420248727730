import { Button, Stack, Typography, Checkbox, useTheme } from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNotifyStatus } from "contexts/status";
import { ViewRailcars } from "./railcars";
import { useGetVideoUrlQuery } from "src/store/api/generatedApi";
import { SelectVideo } from "./selectVideo";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTenant } from "hooks/settings";
import { useSearchParams } from "react-router";
import { LabelVideo } from "./labelling";
import { useLocalStorageState } from "./helpers";
import { JumpToDefaultFrameIndex, useVideoControls } from "./videoControls";

export const VideoLabellingPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const notifyStatus = useNotifyStatus();
  const tenant = useTenant();
  const theme = useTheme();

  const [width, setHeight] = useLocalStorageState("videoLabellingHeight", 1200);
  const [speed, setSpeed] = useLocalStorageState("videoLabellingSpeed", 1);
  const [downsampled, setDownsampled] = useLocalStorageState(
    "videoLabellingDownsampled",
    true
  );
  const [upsideDown, setUpsideDown] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string | null>(null);

  const defaultVideoKey = useSearchParams()[0].get("video-key") ?? null;

  const heightSerialiser = useNumberSerialiser();
  const speedSerialiser = useNumberSerialiser({ min: 0 });

  const controls = useVideoControls(videoRef.current, speed, downsampled);
  const renderedStatusText =
    controls === null
      ? ""
      : controls.frameIndex >= controls.totalFrames && controls.totalFrames > 0
      ? "Vido finshd"
      : statusText;

  const nothing = () => null;

  useHotkeys("=", () => {
    const frameIndex = parseFloat(prompt("Jump to frame index:") ?? "");
    if (!isNaN(frameIndex) && controls) {
      controls.setFrameIndex(frameIndex);
    }
  });

  useHotkeys("up", controls?.toggle ?? nothing);
  useHotkeys("left", controls?.skipBackward ?? nothing);
  useHotkeys("right", controls?.skipForward ?? nothing);

  const [videoKey, setVideoKey] = useState<string | null>(defaultVideoKey);
  const [camera, setCamera] = useState<number>(2);
  const videoUrl = mapLoadedUnpack(
    loadedEndpoint(
      useGetVideoUrlQuery(
        videoKey === null
          ? skipToken
          : { tenantName: tenant, videoKey, camera, downsampled }
      )
    ),
    (url) => url
  );

  const rotation = 180 + (downsampled ? 180 : 0) + (upsideDown ? 180 : 0);

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} p={1}>
        <SelectVideo
          videoKey={videoKey}
          setVideoKey={setVideoKey}
          camera={camera}
          setCamera={setCamera}
        />

        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Height</Typography>
          <ValidatedTextField
            value={width}
            setValue={setHeight}
            serialiser={heightSerialiser}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Speed</Typography>
          <ValidatedTextField
            value={speed}
            setValue={setSpeed}
            serialiser={speedSerialiser}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ width: 150, cursor: "pointer" }}
          onClick={() => setDownsampled((current) => !current)}
        >
          <Typography sx={{ userSelect: "none" }}>Downsampled</Typography>
          <Checkbox
            checked={downsampled}
            size="small"
            sx={{ padding: 0, margin: 0 }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ width: 150, cursor: "pointer" }}
          onClick={() => setUpsideDown((current) => !current)}
        >
          <Typography sx={{ userSelect: "none" }}>Upside down</Typography>
          <Checkbox
            checked={upsideDown}
            size="small"
            sx={{ padding: 0, margin: 0 }}
          />
        </Stack>

        {videoKey === null ? (
          <Button disabled>Railcars</Button>
        ) : (
          <ViewRailcars timestamp={videoKey} />
        )}
      </Stack>

      <Stack
        sx={{ width: width, marginLeft: "auto", marginRight: "auto" }}
        direction="row"
        justifyContent="center"
      >
        {controls ? (
          <JumpToDefaultFrameIndex setFrameIndex={controls.setFrameIndex} />
        ) : null}
        <video
          ref={videoRef}
          src={videoUrl ?? undefined}
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            transform: `rotate(${rotation}deg)`,
            transformOrigin: "center",
          }}
          onEnded={() => notifyStatus({ type: "info", text: "Video finished" })}
        />
        {renderedStatusText === null ? null : (
          <Typography
            sx={{
              position: "absolute",
              fontSize: 140,
              top: "50%",
              transform: "translateY(-50%)",
              color: theme.palette.data.red,
            }}
          >
            {renderedStatusText}
          </Typography>
        )}
      </Stack>

      {controls !== null && videoKey !== null ? (
        <LabelVideo
          key={videoKey}
          videoKey={videoKey}
          controls={controls}
          setStatusText={setStatusText}
          width={width}
          upsideDown={upsideDown}
          setUpsideDown={setUpsideDown}
        />
      ) : null}
    </>
  );
};

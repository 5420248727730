import { useState, useEffect, useCallback } from "react";
import { ExperimentalInputs } from "src/store/api/generatedApi";
import { formatTimestamp, parseTimestamp } from "./timestamps";
import { Setter } from "./inputs/timeline";
import { useSearchParams } from "react-router";
import { Loaded } from "models/loaded";

const getDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    const handleResize = () => setDimensions(getDimensions());

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window, setDimensions, getDimensions]);

  return dimensions;
};

export const derivedProperties = (inputs: ExperimentalInputs) => {
  const allTimestamps = [
    inputs.inventory.timestamp,
    ...inputs.production.map((production) => production.start),
    ...inputs.obtainable.map((obtainable) => obtainable.start),
    ...inputs.production.map((production) => production.end),
    ...inputs.obtainable.map((obtainable) => obtainable.end),
    ...inputs.markers.map((block) => block.start),
    ...inputs.markers.map((block) => block.end),
    ...inputs.periods.map((period) => period.timestamp),
  ];

  const start = formatTimestamp(Math.min(...allTimestamps));
  start.setHours(0, 0, 0, 0);
  const end = formatTimestamp(Math.max(...allTimestamps));
  end.setHours(24, 0, 0, 0);

  const dates = datesBetween(start, end);

  return {
    start: parseTimestamp(start),
    end: parseTimestamp(end),
    dates: dates.map(parseTimestamp),
  };
};

const datesBetween = (today: Date, end: Date): Date[] => {
  const tomorrow = new Date(today);
  tomorrow.setHours(24, 0, 0, 0);
  return today > end ? [] : [today, ...datesBetween(tomorrow, end)];
};

export const useNestedState = <T, K extends keyof T>(
  state: [T, Setter<T>],
  key: K
): [T[K], Setter<T[K]>] => {
  return [
    state[0][key],
    useCallback(
      (update) =>
        state[1]((current) => ({ ...current, [key]: update(current[key]) })),
      [key, state[1]]
    ),
  ];
};

// Should ultimately end up in `search.ts`
export const useId = (): number | null => {
  const urlId = parseInt(useSearchParams()[0].get("id") ?? "");
  if (typeof urlId === "number" && !isNaN(urlId)) {
    return urlId;
  } else {
    return null;
  }
};

// Should ultimately end up in `loaded.ts`
export const doLoaded = <T>(value: Loaded<T>, action: (value: T) => void) => {
  if (value.status === "success") {
    action(value.data);
  }
};

export const valueCounts = <T extends number | string>(
  items: T[]
): Record<T, number> =>
  items.reduce<Record<T, number>>(
    (counts, item) => ({ ...counts, [item]: (counts[item] ?? 0) + 1 }),
    {} as Record<T, number>
  );

export const groupBy = <T, K extends number | string>(
  items: T[],
  key: (item: T) => K
): Record<K, T[]> =>
  items.reduce<Record<K, T[]>>(
    (groups, item) => ({
      ...groups,
      [key(item)]: [...(groups[key(item)] ?? []), item],
    }),
    {} as Record<K, T[]>
  );

import React from "react";
import dayjs from "dayjs";
import { Done } from "@mui/icons-material";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router";

import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { LoadRead, useListLoadsQuery } from "src/store/api/generatedApi";

export const CraneLoads = () => {
  const tenantName = useTenant();

  const createdSince = React.useMemo(() => {
    return dayjs().subtract(1, "day").toISOString();
  }, []);

  const loads = useListLoadsQuery({ tenantName, createdSince });

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        marginY: 4,
        height: "calc(100% - 64px)",
      }}
    >
      {loads.data ? <Body loads={loads.data} /> : <SkeletonBody />}
    </Stack>
  );
};

type Props = {
  loads: LoadRead[];
};

export const SkeletonBody = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ marginBottom: 3 }}>
        <Typography variant="h1">Load the queue</Typography>
      </Skeleton>
      <Skeleton
        variant="rectangular"
        sx={{
          height: "100%",
          width: "300px",
        }}
      />
    </>
  );
};

export const Body = ({ loads }: Props) => {
  const { t } = useTenantTranslation();
  return (
    <>
      <Typography variant="h1" sx={{ marginBottom: 3 }}>
        {t("chargeQueue")}
      </Typography>
      <Stack
        sx={{
          height: "100%",
          overflow: "scroll",
          gap: 2,
          paddingX: 2,
        }}
      >
        {loads.length > 0 ? (
          loads
            .filter(
              (load) =>
                !Object.values(load.baskets).every(
                  (basket) => basket.status === "filled"
                )
            )
            .map((load) => {
              const {
                steel_grade_name,
                copper_group_weight_percent_max,
                mix_id,
                load_id,
              } = load;
              return (
                <LoadCard
                  key={load_id}
                  steelGradeName={steel_grade_name}
                  copperGroupWeightPercentMax={copper_group_weight_percent_max}
                  mixId={mix_id}
                  baskets={[1, 2, 3]}
                />
              );
            })
        ) : (
          <Typography variant="body1" sx={{ margin: "auto 0" }}>
            {t("No charges queued")}
          </Typography>
        )}
      </Stack>
    </>
  );
};

type LoadCardProps = {
  steelGradeName: string;
  copperGroupWeightPercentMax: number;
  mixId: number;
  baskets: number[];
};

const LoadCard = ({
  steelGradeName,
  copperGroupWeightPercentMax,
  mixId,
  baskets,
}: LoadCardProps) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: 2,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid",
        width: 368,
        display: "grid",
        columnGap: 2,
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(4, max-content)",
      }}
    >
      <Typography variant="h5">{steelGradeName}</Typography>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.data.orange,
          gridColumn: 3,
          justifySelf: "end",
        }}
      >
        Cu&nbsp;{(copperGroupWeightPercentMax * 100).toFixed(0)}
      </Typography>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {t("mix")}&nbsp;
        {mixId}
      </Typography>
      {baskets.map((basketNumber, index) => {
        const completed = false;
        return (
          <Button
            component={Link}
            to={`./${mixId}/${basketNumber}`}
            key={basketNumber}
            variant="contained"
            sx={{ gridColumn: index + 1, marginTop: 2 }}
            disabled={completed}
            startIcon={completed ? <Done /> : undefined}
          >
            {t("basket")}&nbsp;{basketNumber}
          </Button>
        );
      })}
    </Box>
  );
};

import { useTenant } from "hooks/settings";
import { useGetMaterialMetadataSetQuery } from "src/store/api/generatedApi";

export const useMaterialNames = () => {
  const tenant = useTenant();
  const { data: metadataSet, isLoading } = useGetMaterialMetadataSetQuery({
    tenantName: tenant,
    materialMetadataSetId: "default",
  });

  const loading = isLoading || metadataSet === undefined;

  return [
    metadataSet?.materials.map((material) => material.name),
    loading,
  ] as const;
};

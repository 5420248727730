import { Box, Typography, useTheme } from "@mui/material";
import { AccountPageLayout } from "components/common/AccountPageLayout";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { DeployedPlansTable } from "./deployedPlansTable";
import { useDeployedPlans } from "contexts/plan";
import { useTenantTranslation } from "hooks/formatters";

export const DeployedPlansPage = () => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <AccountPageLayout title={t("deployedPlans")}>
      <Box sx={{ mt: 5, mb: 2, minHeight: 0 }}>
        <LoadedContent data={useDeployedPlans()} disableFetching>
          {(plans) =>
            plans.length > 0 ? (
              <DeployedPlansTable plans={plans} />
            ) : (
              <Box
                sx={{
                  borderRadius: 0.5,
                  px: 2,
                  py: 1,
                  backgroundColor: "grey.100",
                  display: "inline-block",
                  inlineSize: "fit-content",
                }}
              >
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {t("noDeployedPlans")}
                </Typography>
              </Box>
            )
          }
        </LoadedContent>
      </Box>
    </AccountPageLayout>
  );
};

import { useLocation, useNavigate } from "react-router";
import { useTenant } from "./settings";
import {
  CreateSearch,
  SearchType,
  useCreateAutoRecipeMutation,
  useCreateSearchMutation,
} from "src/store/api/generatedApi";
import { useFormatLocalDate } from "hooks/useFormatLocalDate";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "./formatters";
import { useCallback } from "react";

export const useNavigateTo = (replace?: boolean) => {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const search = currentLocation.search;
  const pathName = currentLocation.pathname;
  const navigateTo = useCallback(
    ({
      location,
      params,
    }: {
      location?: string;
      params?: { [name: string]: string | null | undefined };
    }) => {
      const currentParams = new URLSearchParams(search);

      Object.entries(params ?? {}).forEach(([name, value]) => {
        if (value != null) {
          return currentParams.set(name, value);
        } else {
          currentParams.delete(name);
        }
      });

      void navigate(
        {
          pathname: location ?? pathName,
          search: currentParams.toString(),
        },
        { replace }
      );
    },
    [search, pathName, navigate]
  );
  return navigateTo;
};

export const useNavigateToTenantHome = () => {
  const navigateTo = useNavigateTo();
  const navigateToTenantHome = useCallback(
    (tenant: string) => navigateTo({ location: `/${tenant}` }),
    [navigateTo]
  );

  return navigateToTenantHome;
};

export const useNavigateToHome = () => {
  const navigateTo = useNavigateTo();
  const tenant = useTenant();
  const navigateToHome = useCallback(
    () => navigateTo({ location: `/${tenant}/v3` }),
    [navigateTo, tenant]
  );

  return navigateToHome;
};

export const useNavigateToNewBlankSearch = () => {
  const tenant = useTenant();
  const [createSearch, createSearchMutation] = useCreateSearchMutation();
  const navigate = useNavigateToSearch();
  const formatDate = useFormatLocalDate("datetime");
  const createSearchObject: CreateSearch = {
    name: formatDate(new Date()),
    search_type: "purchasing",
    search: null,
  };
  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();
  const errorMessage = t("unknownError");
  const navigateToNewBlankSearch = useCallback(
    () =>
      createSearch({
        tenantName: tenant,
        createSearch: createSearchObject,
      })
        .unwrap()
        .then(({ search_id }) => navigate(search_id))
        .catch(() => notifyStatus({ type: "error", text: errorMessage })),
    [
      tenant,
      createSearchObject,
      createSearch,
      navigate,
      notifyStatus,
      errorMessage,
    ]
  );

  return [navigateToNewBlankSearch, createSearchMutation] as const;
};

export const useNavigateToNewAutoSearch = () => {
  const tenant = useTenant();
  const [createSearch, createAutoRecipeMutation] =
    useCreateAutoRecipeMutation();
  const navigate = useNavigateToSearch();
  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();
  const errorMessage = t("unknownError");

  const navigateToNewAutoSearch = useCallback(
    (searchType: SearchType, viewFromTimestamp?: string) =>
      createSearch({
        tenantName: tenant,
        autoRecipeType: searchType,
        viewFromTimestamp,
      })
        .unwrap()
        .then(({ search_id }) => navigate(search_id))
        .catch(() => notifyStatus({ type: "error", text: errorMessage })),
    [tenant, createSearch, navigate, notifyStatus, errorMessage]
  );

  return [navigateToNewAutoSearch, createAutoRecipeMutation] as const;
};

export const useNavigateToSearch = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToSearch = useCallback(
    (searchId: number, period = 1) =>
      navigateTo({
        location: `/${tenant}/v3/search`,
        params: {
          search: searchId.toString(),
          plan: null,
          period: period.toString(),
        },
      }),
    [navigateTo, tenant]
  );

  return navigateToSearch;
};

export const useNavigateToDebugSearch = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToDebugSearch = useCallback(
    (searchId: number) =>
      navigateTo({
        location: `/${tenant}/v3/debug-search`,
        params: { search: searchId.toString(), plan: null },
      }),
    [navigateTo, tenant]
  );

  return navigateToDebugSearch;
};

export const useNavigateToPlan = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToPlan = useCallback(
    (planId: number, searchId: number, period = 1) => {
      navigateTo({
        location: `/${tenant}/v3/plan`,
        params: {
          plan: planId.toString(),
          search: searchId.toString(),
          period: period.toString(),
        },
      });
    },
    [navigateTo, tenant]
  );

  return navigateToPlan;
};

export const useSearchContextGroupListRoute = () => {
  const tenantName = useTenant();
  return `/${tenantName}/v3/search_context_group`;
};

export const useNavigateToSearchContextGroupList = () => {
  const location = useSearchContextGroupListRoute();
  const navigateTo = useNavigateTo();
  const navigateToSearchContextGroupList = useCallback(() => {
    navigateTo({
      location,
    });
  }, [navigateTo, location]);

  return navigateToSearchContextGroupList;
};

export const useNavigateToSearchContextGroup = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToSearchContextGroup = useCallback(
    ({
      searchContextGroupId,
      searchContextId,
    }: {
      searchContextGroupId: number;
      searchContextId: number;
    }) => {
      navigateTo({
        location: `/${tenant}/v3/search_context_group/${searchContextGroupId}/search_context/${searchContextId}`,
      });
    },
    [navigateTo, tenant]
  );

  return navigateToSearchContextGroup;
};

export const useNavigateToSearchContextMixMaterialLimitSet = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToSearchContextMixMaterialLimitSet = useCallback(
    ({
      searchContextGroupId,
      searchContextId,
      mixMaterialLimitSetId,
    }: {
      searchContextGroupId: number;
      searchContextId: number;
      mixMaterialLimitSetId: number;
    }) => {
      navigateTo({
        location: `/${tenant}/v3/search_context_group/${searchContextGroupId}/search_context/${searchContextId}/mix_material_limit_set/${mixMaterialLimitSetId}`,
      });
    },
    [navigateTo, tenant]
  );

  return navigateToSearchContextMixMaterialLimitSet;
};

export const useNavigateToSearchPlanPeek = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();
  const navigateToSearchPlanPeek = useCallback(
    (searchId: number, planId: number) => {
      navigateTo({
        location: `/${tenant}/v3/search`,
        params: { search: searchId.toString(), plan: planId.toString() },
      });
    },
    [navigateTo, tenant]
  );

  return navigateToSearchPlanPeek;
};

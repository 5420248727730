import { Box, Chip, SxProps, useTheme } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

type Props = {
  number: 1 | 2;
  sx?: SxProps;
};

const defaultSx = {};

export const CraneChip = ({ number, sx = defaultSx }: Props) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const label = t("crane") + " " + number;

  return (
    <Chip
      sx={sx}
      label={label}
      size="small"
      icon={
        <Box
          sx={{
            backgroundColor: theme.palette.success.main,
            padding: 0.5,
            borderRadius: "50%",
          }}
        />
      }
    />
  );
};

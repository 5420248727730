import { Setter } from "hooks/state/syncing";
import { ExportPlanState } from "./state";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useListConfirmedChargeScheduleMetaQuery } from "store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";
import { useEffect, useMemo } from "react";

export const AwaitingConfirmation = ({
  state,
  setState,
}: {
  state: ExportPlanState;
  setState: Setter<ExportPlanState>;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const { data: allConfirmed, isError } =
    useListConfirmedChargeScheduleMetaQuery(
      {
        tenantName: useTenant(),
        planId: state.plan.id,
      },
      { pollingInterval: 2000 }
    );

  // Only those confirmed schedules appearing in the list of schedules that were
  // published on this run through the modal
  const confirmed = useMemo(
    () =>
      allConfirmed?.filter((confirmed) =>
        (state.published ?? []).some(
          (published) =>
            published.fdm_charge_schedule_id ===
            confirmed.fdm_charge_schedule_id
        )
      ) ?? [],
    [allConfirmed, state.published]
  );

  useEffect(() => {
    if (
      state.published != null &&
      confirmed.length === state.published.length
    ) {
      setState((current) => ({
        ...current,
        stage: "awaitingConfirmationSuccess",
        confirmed,
      }));
    }
  }, [confirmed, state.published, setState]);

  useEffect(() => {
    if (isError) {
      setState((current) => ({
        ...current,
        stage: "awaitingConfirmationError",
      }));
    }
  }, [isError, setState]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
        <Typography variant="h5" sx={{ mt: 2.5 }}>
          {state.published == null
            ? `${t("sendingToLevel2")}...`
            : `${t("waitingForCodes")}...`}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, color: theme.palette.text.secondary }}
        >
          {state.published == null
            ? t("doNotCloseThisWindow")
            : `${t("doNotCloseThisWindow")} ${t("thisMayTakeAFewMinutes")}`}
        </Typography>
      </Stack>
    </Box>
  );
};

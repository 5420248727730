import { Error } from "@mui/icons-material";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export const SendSmsError = ({ close }: { close: () => void }) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <Error sx={{ color: "error.main", height: 28, width: 28 }} />
        <Typography variant="h5" sx={{ mt: 1 }}>
          {t("errorSendingSms")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, color: theme.palette.text.secondary }}
        >
          {t("sorryForInconvenience")}
        </Typography>
        <Button variant="contained" onClick={close} sx={{ mt: 3 }}>
          {t("close")}
        </Button>
      </Stack>
    </Box>
  );
};

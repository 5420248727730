import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTenantTranslation } from "hooks/formatters";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { LoadingButton } from "@mui/lab";

export const RenameSessionModal = ({
  open,
  close,
  rename,
  prefillName = "",
  originalName,
}: {
  open: boolean;
  close: () => void;
  rename: (name: string) => Promise<void>;
  originalName: string;
  prefillName?: string;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  const [name, setName] = useState(prefillName);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { parse } = useStringSerialiser({ trim: true });
  const validatedName = parse(name);

  const hasEdited = originalName !== name;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;
    switch (key.toLowerCase()) {
      case "enter": {
        if (hasEdited) {
          handleSubmit();
        }
      }
    }
  };

  const handleSubmit = () => {
    if (validatedName.valid && hasEdited) {
      void rename(validatedName.value)
        .then(() => close())
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{t("renameSearch")}</DialogTitle>

      <DialogContent>
        <TextField
          sx={{ mt: 1 }}
          fullWidth
          size="small"
          color="secondary"
          value={name}
          onChange={({ target }) => setName(target.value)}
          label={t("searchName")}
          autoFocus
          onFocus={(event) =>
            event.currentTarget.setSelectionRange(0, name.length)
          }
          onKeyDown={handleKeyDown}
        />

        {error ? (
          <Typography sx={{ pl: 1, pt: 1, color: theme.palette.error.main }}>
            {t("errorRenamingSearch")}
          </Typography>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={close}>
          {t("cancel")}
        </Button>

        <LoadingButton
          variant="contained"
          disabled={!hasEdited || !validatedName.valid}
          loading={loading}
          onClick={() => handleSubmit()}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

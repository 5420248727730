import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource-variable/roboto-mono";
import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  firebaseConfig,
  sentryReplayErrorSampleRate,
  sentryReplaySessionSampleRate,
  VERSION,
} from "./constants";
import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
} from "firebase/auth";
import { ProvideStatusNotifications } from "contexts/status";
import { AppRoutes } from "./routes/app";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

const container = document.getElementById("root");

async function startApp() {
  if (container !== null) {
    await tryLoadAndStartRecorder({
      projectId: import.meta.env.VITE_METICULOUS_PROJECT_ID,
    });

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      release: `scrap-chef-frontend@${VERSION}`,
      integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: sentryReplaySessionSampleRate,
      replaysOnErrorSampleRate: sentryReplayErrorSampleRate,
    });

    const firebaseApp = initializeApp(firebaseConfig);
    initializeAuth(firebaseApp, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: browserPopupRedirectResolver,
    });

    const root = createRoot(container, {
      // Callback called when an error is thrown and not caught by an Error Boundary.
      onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
        console.warn("Uncaught error", error, errorInfo.componentStack);
      }),
      // Callback called when React catches an error in an Error Boundary.
      onCaughtError: Sentry.reactErrorHandler(),
      // Callback called when React automatically recovers from errors.
      onRecoverableError: Sentry.reactErrorHandler(),
    });

    root.render(
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ProvideStatusNotifications>
              <AppRoutes />
            </ProvideStatusNotifications>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    );
  }
}

void startApp();
export { getBase64 } from "./helpers";

import React, { useState, type JSX } from "react";
import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
  IconButton,
  Skeleton,
} from "@mui/material";
import { createContext, createUseContext } from "../boundary/context";
import { usePeriodTabTranslation } from "hooks/formatters";
import {
  Period,
  minimumPeriod,
  usePeriodIndex,
  useSetPeriodIndex,
} from "hooks/periodIndex";
import { usePeriods } from "contexts/search/provider";
import { AddCircleOutline } from "@mui/icons-material";
import { useHotkeys } from "react-hotkeys-hook";
import { doLoaded } from "models/loaded";
import { hotkeys } from "hooks/hotkeys";
import { SyncedSearchPeriod } from "src/store/api/generatedApi";
import { useTenantProductionPlan } from "hooks/useTenantProductionPlan";
import { TabHeader } from "./tabHeader";
import { DeletePeriodConfirmation, PeriodMetadata } from "./actions";

export const blankPeriod: SyncedSearchPeriod = {
  production_plan: {
    product_group_items: [],
    steel_grade_items: null,
  },
  obtainable_bundles: [],
  target_inventory: [],
  material_exclusivity: [],
  name: null,
  is_deployable: false,
  suppress_mix_material_exclusivity_constraints: false,
  suppress_min_feasible_mass_constraints: false,
  optimisation_objective_weighting: 1.0,
};

type Props = {
  trailingComponent?: JSX.Element;
};

const selectedStyle = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.common.black,
  borderBottomColor: theme.palette.common.white,
  cursor: "default",
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
});

const getStyle = (isSelected: boolean, theme: Theme): SxProps<Theme> => {
  const baseStyle = {
    px: 2,
    py: 1,
    borderStyle: "solid",
    borderWidth: "1px",
    marginBottom: "-1px",
    borderColor: "transparent",
    cursor: "pointer",
  };
  if (isSelected) {
    return {
      ...baseStyle,
      ...selectedStyle(theme),
    };
  } else {
    return baseStyle;
  }
};

const Context = createContext<Period>();

export const usePeriodTab = createUseContext(Context, "period_tab");

export const PeriodTabs = ({
  children,
  trailingComponent,
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const period = usePeriodIndex();
  const setPeriod = useSetPeriodIndex();
  const periodTabTranslation = usePeriodTabTranslation();
  const [searchPeriods, setSearchPeriods] = usePeriods();
  const tenantProductionPlan = useTenantProductionPlan();

  useHotkeys(hotkeys.nextPeriod, () =>
    doLoaded(searchPeriods, (periods) =>
      setPeriod(period < periods.length ? ((period + 1) as Period) : period)
    )
  );

  useHotkeys(
    hotkeys.previousPeriod,
    () => setPeriod(period === 1 ? (1 as Period) : ((period - 1) as Period)),
    { splitKey: "!" }
  );

  const addPeriod = (newPeriodIndex: Period) => {
    setSearchPeriods((periods) => [
      ...periods,
      { ...blankPeriod, production_plan: tenantProductionPlan },
    ]);
    setPeriod(newPeriodIndex);
  };

  const editPeriod = (index: number) => (metadata: PeriodMetadata) => {
    setSearchPeriods((searchPeriods) => {
      return [
        ...searchPeriods.slice(0, index),
        { ...searchPeriods[index]!, ...metadata },
        ...searchPeriods.slice(index + 1),
      ];
    });
  };

  const [periodToDelete, setPeriodToDelete] = useState<Period | null>(null);

  return (
    <>
      <Stack sx={{ flexDirection: "column", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomColor: theme.palette.common.black,
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            backgroundColor: theme.palette.grey[50],
            paddingX: 2,
            paddingTop: 1,
          }}
        >
          <Box sx={{ display: "flex" }}>
            {searchPeriods.status === "success" ? (
              <>
                {searchPeriods.data.map(
                  ({ name, start_timestamp, end_timestamp }, index) => {
                    const isSelected = Number(period) === index + minimumPeriod;
                    return (
                      <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sx={getStyle(isSelected, theme)}
                        onClick={() =>
                          setPeriod((index + minimumPeriod) as Period)
                        }
                      >
                        <TabHeader
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${index}`}
                          selected={isSelected}
                          index={(index + minimumPeriod) as Period}
                          metadata={{
                            name: name ?? null,
                            start_timestamp: start_timestamp ?? null,
                            end_timestamp: end_timestamp ?? null,
                          }}
                          setMetadata={editPeriod(index)}
                          onDelete={() =>
                            setPeriodToDelete((index + minimumPeriod) as Period)
                          }
                        />
                      </Box>
                    );
                  }
                )}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() =>
                    addPeriod((searchPeriods.data.length + 1) as Period)
                  }
                  sx={{ px: 2 }}
                >
                  <AddCircleOutline fontSize="inherit" />
                </IconButton>
              </>
            ) : (
              <Box sx={getStyle(true, theme)}>
                <Typography
                  variant="button"
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textWrap: "nowrap",
                    display: "block",
                  }}
                >
                  <Skeleton sx={{ width: 60 }} />
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ height: "100%", pb: 0.25 }}>
            {trailingComponent ?? null}
          </Box>
        </Box>

        {children}
      </Stack>

      {periodToDelete !== null &&
      searchPeriods.status === "success" &&
      searchPeriods.data[periodToDelete - 1] ? (
        <DeletePeriodConfirmation
          periodToDelete={periodToDelete}
          setPeriodToDelete={setPeriodToDelete}
          name={
            searchPeriods.data[periodToDelete - 1]?.name ??
            periodTabTranslation(periodToDelete)
          }
        />
      ) : null}
    </>
  );
};

import React from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  ArrowBack,
  ArrowForward,
  Attachment,
  CheckCircle,
  Error,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateRange,
  DateTimeRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";

import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/pl";

import { useTenantTranslation } from "hooks/formatters";
import { useShortLanguage, useTenant } from "hooks/settings";
import { backendApi } from "src/store/api/enhancedApi";
import { useExcelName } from "hooks/useExcelDownloadName";
import {
  DeployParameters,
  SupportedLanguage,
} from "src/store/api/generatedApi";
import { useServerIds } from "contexts/search/provider";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { usePlanId } from "components/common/boundary/PlanId";
import { usePlanMixesFromBackend } from "components/plan/topButtons/emailPlan/useCandidateMixes.ts";

type Props = {
  toNextState: () => void;
  toInitialState: () => void;
};

type BodyProps = {
  toNextState: () => void;
  toInitialState: () => void;
  startAt: Dayjs;
  endAt: Dayjs;
};

enum State {
  Review,
  Sending,
  Success,
  Error,
}

export const ShareViaEmail = ({ toNextState, toInitialState }: Props) => {
  const planMixes = usePlanMixesFromBackend();
  if (planMixes.status === "success") {
    const startAt = planMixes.data.start_at
      ? dayjs(new Date(planMixes.data.start_at))
      : dayjs(new Date());
    const endAt = planMixes.data.end_at
      ? dayjs(new Date(planMixes.data.end_at))
      : startAt.add(6, "hours");
    return (
      <Body
        toNextState={toNextState}
        toInitialState={toInitialState}
        startAt={startAt}
        endAt={endAt}
      />
    );
  } else {
    return null;
  }
};

export const Body = ({
  toNextState,
  toInitialState,
  startAt,
  endAt,
}: BodyProps) => {
  const { t } = useTenantTranslation();
  const [state, setState] = React.useState(State.Review);
  const { searchId } = useServerIds();
  const theme = useTheme();
  const planId = usePlanId();
  const tenantName = useTenant();
  const language = useShortLanguage() as SupportedLanguage;
  const fileName = useExcelName();
  const formatDate = useFormatLocalDateString("date");
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    startAt,
    endAt,
  ]);
  const [startDate, endDate] = dateRange;
  const dateLocale = language === "EN" ? "en-gb" : language.toLowerCase();

  const tenantContacts = backendApi.endpoints.getTenantContacts.useQuery({
    tenantName,
  });
  const [emailPlan] = backendApi.endpoints.deployPlan.useMutation();

  const link = `${window.location.origin}/${tenantName}/v3/search?search=${searchId}&plan=${planId}`;

  const handleSendEmail = async () => {
    if (startDate !== null && endDate !== null) {
      setState(State.Sending);
      try {
        const deployParameters: DeployParameters = {
          process: "email",
          language: language,
          start_at: startDate.toISOString(),
          end_at: endDate.toISOString(),
        };
        await emailPlan({ tenantName, planId, deployParameters }).unwrap();
        setState(State.Success);
      } catch (e) {
        setState(State.Error);
      }
    }
  };

  const handleDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    setDateRange(newDateRange);
  };

  switch (state) {
    case State.Review: {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            margin: "0 auto",
            width: "500px",
            marginTop: 10,
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ color: theme.palette.text.primary }}
          >
            {t("recipients")}:{" "}
            {tenantContacts.isSuccess ? (
              <Typography
                variant="body1"
                component="span"
                sx={{ color: theme.palette.text.secondary }}
              >
                {tenantContacts.data.join(", ")}
              </Typography>
            ) : (
              <>
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={400}
                />
                &nbsp; &nbsp;
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={250}
                />
                &nbsp; &nbsp;
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={125}
                />
              </>
            )}
          </Typography>
          <Stack gap={1}>
            <Typography variant="body1" component="span">
              {t("emailSubject")}:
            </Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "2px",
              }}
            >
              <Typography variant="body1">{`${t("gattierung")} (${formatDate(
                new Date().toDateString()
              )})`}</Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1">{t("emailBody")}</Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "2px",
              }}
            >
              <Typography variant="body1">
                {t("aNewGattierungIsReady")}
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{
                  textDecoration: "underline",
                  color: theme.palette.text.primary,
                }}
              >
                <a href={link}>{link}</a>
              </Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1">{t("attachment")}</Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "fit-content",
              }}
            >
              <Attachment />
              <Typography variant="body1" component="span">
                {fileName}
              </Typography>
            </Box>
          </Stack>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={dateLocale}
          >
            <DateTimeRangePicker
              localeText={{ start: t("planStart"), end: t("planEnd") }}
              value={[startDate, endDate]}
              onChange={handleDateRangeChange}
              ampm={false}
            />
          </LocalizationProvider>
          <Box sx={{ gap: 1, alignItems: "center", display: "flex" }}>
            <Button
              variant="outlined"
              sx={{ alignSelf: "start" }}
              onClick={toNextState}
            >
              {t("skip")}
            </Button>
            <Button
              variant="contained"
              sx={{ alignSelf: "start" }}
              onClick={handleSendEmail}
              disabled={!tenantContacts.isSuccess}
            >
              {t("sendEmail")}
            </Button>
          </Box>
        </Stack>
      );
    }
    case State.Sending: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("sendingEmail")}...
          </Typography>
          <Typography variant="body1">
            {t("pleaseDoNotCloseThisWindow")}
          </Typography>
        </Stack>
      );
    }
    case State.Success: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CheckCircle color="success" />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("emailSentSuccesfully")}
          </Typography>
          <Button
            variant="contained"
            onClick={toNextState}
            endIcon={<ArrowForward />}
          >
            {t("next")}
          </Button>
        </Stack>
      );
    }
    case State.Error: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Error color="error" fontSize="large" />
          <Typography variant="body1">{t("unknownError")}</Typography>
          <Button
            variant="outlined"
            onClick={toInitialState}
            startIcon={<ArrowBack />}
          >
            {t("back")}
          </Button>
        </Stack>
      );
    }
  }
};

import React from "react";
import Decimal from "decimal.js";
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { Navigate, useParams } from "react-router";

import {
  useNavigateToSearchContextGroup,
  useSearchContextGroupListRoute,
} from "hooks/navigation";
import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";

import {
  ChefGroup,
  MaterialLimitCoefficientRead,
  MixMaterialLimitRead,
  useGetMixMaterialLimitSetQuery,
  useGetSearchContextQuery,
  useListMaterialConstraintClassesQuery,
} from "src/store/api/generatedApi";

import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { ChevronLeft } from "@mui/icons-material";

type MixMaterialLimitsTableProps = {
  chefGroups: ChefGroup[];
  materialsId: number;
  materialPhysicsId: number;
  searchContextId: number;
  searchContextGroupId: number;
  mixMaterialLimitSetId: number;
};

type Row = MixMaterialLimitRead;

type NumberValueSetterArgs = {
  min?: number;
  max?: number;
  dp?: number;
  field: Exclude<keyof Row, "id" | "chef_group_id" | "name">;
};

const numberValueSetter =
  ({
    min = -Infinity,
    max = Infinity,
    dp = 2,
    field,
  }: NumberValueSetterArgs): GridColDef<Row>["valueSetter"] =>
  (value, row) => {
    const numberedValue = Number(value);
    if (!isNaN(numberedValue) && numberedValue >= min && numberedValue <= max) {
      return { ...row, [field]: Number(numberedValue.toFixed(dp)) };
    } else {
      return row;
    }
  };

type CoefficientsCellProps = {
  coefficients: MaterialLimitCoefficientRead[];
  materialConstraintClasses: Record<number, string>;
};

const CoefficientsCell = ({
  coefficients,
  materialConstraintClasses,
}: CoefficientsCellProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "start",
        gap: 0.5,
        paddingY: 0.5,
        paddingX: 1,
      }}
    >
      {coefficients.map((coefficient) => {
        const coefficientNumber = new Decimal(coefficient.coefficient);
        return (
          <Chip
            key={coefficient.id}
            label={
              <>
                {!coefficientNumber.equals(1) ? (
                  <>
                    <strong>
                      {coefficientNumber.toDecimalPlaces(3).toString()}
                    </strong>
                    &nbsp;&nbsp;
                  </>
                ) : null}
                {materialConstraintClasses[
                  coefficient.material_constraint_class_id
                ] ?? "Unknown"}
              </>
            }
          />
        );
      })}
    </Box>
  );
};

type NameCellProps = {
  name: string;
};

const NameCell = ({ name }: NameCellProps) => {
  const { t } = useTenantTranslation();
  return t(name);
};

const buildDefaultColumns = (
  materialConstraintClasses: Record<number, string> // chefGroupNames: Record<number, string>
): GridColDef<Row>[] => [
  {
    field: "coefficients_signature",
    headerName: "Name",
    display: "flex",
    width: 200,
    valueGetter: (_, row) => {
      if (row.name) {
        return row.name;
      } else {
        return row.coefficients_signature;
      }
    },
    renderCell: ({ value }: GridRenderCellParams<Row, string>) => {
      if (value) {
        return <NameCell name={value} />;
      } else {
        return null;
      }
    },
  },
  {
    field: "hardness",
    headerName: "Hardness",
    display: "flex",
    editable: true,
    valueSetter: numberValueSetter({
      min: 0,
      max: 1,
      dp: 2,
      field: "hardness",
    }),
  },
  {
    field: "min_mass",
    display: "flex",
    headerName: "Min mass",
    editable: true,
    valueSetter: numberValueSetter({
      min: 0,
      field: "min_mass",
    }),
  },
  {
    field: "soft_min_mass",
    headerName: "Soft min mass",
    display: "flex",
    editable: true,
    valueSetter: numberValueSetter({
      min: 0,
      field: "soft_min_mass",
    }),
  },
  {
    field: "soft_max_mass",
    headerName: "Soft max mass",
    display: "flex",
    editable: true,
    valueSetter: numberValueSetter({
      min: 0,
      field: "soft_max_mass",
    }),
  },
  {
    field: "max_mass",
    headerName: "Max mass",
    display: "flex",
    editable: true,
    valueSetter: numberValueSetter({
      min: 0,
      field: "max_mass",
    }),
  },
  {
    field: "coefficients",
    headerName: "Coefficients",
    cellClassName: "coefficients",
    width: 300,
    renderCell: ({ row: { coefficients } }: GridRenderCellParams<Row>) => {
      return (
        <CoefficientsCell
          coefficients={coefficients}
          materialConstraintClasses={materialConstraintClasses}
        />
      );
    },
  } as GridColDef<Row>,
];

const buildColumns = (
  materialConstraintClasses: Record<number, string>
): GridColDef<Row>[] => {
  return [...buildDefaultColumns(materialConstraintClasses)];
};

const MixMaterialLimits = ({
  chefGroups,
  materialPhysicsId,
  materialsId,
  mixMaterialLimitSetId,
  searchContextGroupId,
  searchContextId,
}: MixMaterialLimitsTableProps) => {
  const tenantName = useTenant();
  const materialConstraintClasses = useListMaterialConstraintClassesQuery({
    tenantName,
    materialPhysicsSetId: materialPhysicsId,
    materialMetadataSetId: materialsId,
  });
  const [selectedChefGroup, setSelectedChefGroup] = React.useState<null | {
    id: number;
    name: string;
  }>({
    id: chefGroups[0]!.id!,
    name: chefGroups[0]!.name,
  });
  const mixMaterialLimitSet = useGetMixMaterialLimitSetQuery(
    selectedChefGroup
      ? {
          tenantName,
          mixMaterialLimitSetId,
          chefGroupId: selectedChefGroup.id,
        }
      : { tenantName, mixMaterialLimitSetId },
    { skip: selectedChefGroup === null }
  );
  const navigateToSearchContextGroup = useNavigateToSearchContextGroup();

  if (materialConstraintClasses.data && mixMaterialLimitSet.data) {
    const materialConstraintClassIdToName = typeSafeObjectFromEntries(
      materialConstraintClasses.data.map(({ id, name }) => [id, name])
    );
    const columns = buildColumns(materialConstraintClassIdToName);
    return (
      <Box
        sx={{
          marginX: 6,
          marginY: 3,
          display: "grid",
          gridTemplateColumns: "min-content 1fr",
          gap: 1,
          height: "calc(100% - 48px)",
        }}
      >
        <IconButton
          sx={{ padding: 0, width: "fit-content" }}
          onClick={() =>
            navigateToSearchContextGroup({
              searchContextGroupId,
              searchContextId,
            })
          }
        >
          <ChevronLeft />
        </IconButton>
        <Typography variant="h1">Mix material limits</Typography>
        <Autocomplete
          sx={{ width: 400, gridColumn: "1/-1" }}
          options={chefGroups.map((chefGroup) => ({
            name: chefGroup.name,
            id: chefGroup.id,
          }))}
          value={selectedChefGroup}
          onChange={(_, value) =>
            setSelectedChefGroup(
              value ? { id: value.id!, name: value.name } : null
            )
          }
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} sx={{ fontSize: 40 }} />
          )}
          size="small"
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />

        <DataGridPremium
          sx={{
            gridColumn: "1/-1",
            minHeight: 0,
            [".coefficients"]: { padding: 0 },
          }}
          columns={columns}
          getRowHeight={() => "auto"}
          rows={mixMaterialLimitSet.data.mix_material_limits}
          editMode="cell"
        />
      </Box>
    );
  } else {
    return null;
  }
};

const ParameterisedMixMaterialLimitsPage = ({
  mixMaterialLimitSetId,
  searchContextId,
  searchContextGroupId,
}: Props) => {
  const tenantName = useTenant();
  const redirect = useSearchContextGroupListRoute();
  const searchContext = useGetSearchContextQuery({
    tenantName,
    searchContextId,
  });
  if (
    (searchContext.data &&
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      searchContext.data.mix_material_limit_set_id !== mixMaterialLimitSetId) ||
    searchContext.isError
  ) {
    return <Navigate to={redirect} />;
  } else if (searchContext.data) {
    return (
      <MixMaterialLimits
        mixMaterialLimitSetId={mixMaterialLimitSetId}
        chefGroups={searchContext.data.chef_groups}
        materialsId={searchContext.data.materials_id}
        materialPhysicsId={searchContext.data.material_physics_id}
        searchContextGroupId={searchContextGroupId}
        searchContextId={searchContextId}
      />
    );
  } else {
    return <Typography variant="h1">Loading...</Typography>;
  }
};

export const MixMaterialLimitsPage = () => {
  const {
    mix_material_limit_set_id,
    search_context_id,
    search_context_group_id,
  } = useParams();
  const redirect = useSearchContextGroupListRoute();
  if (
    !mix_material_limit_set_id ||
    !search_context_group_id ||
    !search_context_id ||
    isNaN(Number(mix_material_limit_set_id)) ||
    isNaN(Number(search_context_group_id)) ||
    isNaN(Number(search_context_id))
  ) {
    return <Navigate to={redirect} />;
  }
  return (
    <ParameterisedMixMaterialLimitsPage
      mixMaterialLimitSetId={Number(mix_material_limit_set_id)}
      searchContextId={Number(search_context_id)}
      searchContextGroupId={Number(search_context_group_id)}
    />
  );
};

type Props = {
  mixMaterialLimitSetId: number;
  searchContextId: number;
  searchContextGroupId: number;
};

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  SessionSummary,
  useCopySearchMutation,
  useRenameSessionMutation,
} from "store/api/generatedApi";
import { Delete, Edit, MoreVert, ContentCopy } from "@mui/icons-material";
import { useState } from "react";
import { RenameSessionModal } from "./RenameSessionModal";
import { useTenant } from "hooks/settings";
import { useTenantTranslation } from "hooks/formatters";
import { DeleteSessionModal } from "./DeleteSessionModal";
import { useNotifyStatus } from "contexts/status";
import React from "react";

type Props = {
  session: SessionSummary;
};

export const SessionOptionsButton = ({ session }: Props) => {
  const { t } = useTenantTranslation();
  const tenant = useTenant();

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [renameSession] = useRenameSessionMutation();
  const [copySearch] = useCopySearchMutation();

  const notifyStatus = useNotifyStatus();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleClickRename = () => {
    setRenameModalOpen(true);
    handleClose();
  };

  const handleClickDelete = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  const handleClickCopy = () => {
    handleClose();
    copySearch({
      tenantName: tenant,
      sessionId: session.session_id,
      copySearch: { name: `${session.name} (${t("copyNoun")})` },
    })
      .unwrap()
      .then(() => notifyStatus({ type: "success", text: t("searchCopied") }))
      .catch(() =>
        notifyStatus({ type: "error", text: t("errorCopyingSearch") })
      );
  };

  const handleRename = React.useCallback(async (name: string) => {
    await renameSession({
      tenantName: tenant,
      sessionId: session.session_id,
      renameSession: { name },
    });
  }, []);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClickRename}>
          <ListItemIcon>
            <Edit fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="button">{t("rename")}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <Delete fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="button">{t("delete")}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickCopy}>
          <ListItemIcon>
            <ContentCopy fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="button">{t("makeACopy")}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      {renameModalOpen ? (
        <RenameSessionModal
          open={renameModalOpen}
          close={() => setRenameModalOpen(false)}
          originalName={session.name}
          prefillName={session.name}
          rename={handleRename}
        />
      ) : null}

      {deleteModalOpen ? (
        <DeleteSessionModal
          open={deleteModalOpen}
          close={() => setDeleteModalOpen(false)}
          sessionId={session.session_id}
        />
      ) : null}
    </>
  );
};

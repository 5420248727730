import { LoadingResults } from "./LoadingResults";
import { FailedSearch } from "./FailedSearch";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { Box, FormLabel, Switch, Typography, useTheme } from "@mui/material";
import { SuccessfulSearch } from "./SuccessfulSearch";
import { SearchResultsLayout } from "./SearchResultsLayout";
import {
  OptimisationFailureReason,
  PeriodSummary,
  Plan,
  useSendPlanNotificationMutation,
} from "store/api/generatedApi";
import { useSearch, useHasDefaultSets } from "contexts/search/provider";
import { sorted } from "helpers";
import { useTenantTranslation } from "hooks/formatters";
import { useState } from "react";
import { useIsAdmin, useTenant } from "hooks/settings.tsx";
import { PlanCardData } from "./planCard/data";
import { useDefaultPlanName } from "../../plan/name";
import { useNavigateToSearchPlanPeek } from "hooks/navigation";
// import { Admin } from "../../common/boundary/admin";

export const SearchResults = () => {
  const {
    results: { results, isStale: stale },
    clientSearchId: searchId,
  } = useSearch();

  const hasDefaultSets = useHasDefaultSets();

  const tenant = useTenant();
  const [sendNotification] = useSendPlanNotificationMutation();
  const navigateToSearchPlanPeek = useNavigateToSearchPlanPeek();
  const defaultPlanName = useDefaultPlanName();

  const doNavigate = (planCard: PlanCardData) => {
    void sendNotification({
      tenantName: tenant,
      planId: planCard.planId,
      planName: planCard.planName ?? defaultPlanName(planCard.planId),
    });
    navigateToSearchPlanPeek(planCard.searchId, planCard.planId);
  };

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      <LoadedContent
        data={hasDefaultSets}
        disableFetching
        loading={null}
        waiting={null}
      >
        {(hasDefaultSets) => {
          return (
            <LoadedContent
              data={results}
              loading={<LoadingResults />}
              waiting={<LoadingResults />}
              disableFetching
            >
              {(result, running) => {
                const suboptimalPlans = (result?.plans ?? []).filter((plan) =>
                  ["failed_suboptimal", "succeeded_suboptimal"].includes(
                    plan.optimisation_status
                  )
                );
                const longestSuboptimalTimeLimit =
                  suboptimalPlans.length > 0
                    ? Math.max(
                        ...suboptimalPlans.map((plan) => plan.time_limit)
                      )
                    : undefined;
                return (
                  <>
                    {running ? <LoadingResults /> : null}

                    <SearchResultsLayout
                      isNewSearch={result === null && !running}
                      inputsStale={!running && stale}
                      constraintsStale={
                        !running && !hasDefaultSets && searchId !== null
                      }
                      longestSuboptimalTimeLimit={longestSuboptimalTimeLimit}
                    >
                      {result == null && !running ? null : (
                        <SearchResultsContent
                          running={running}
                          plans={result?.plans ?? []}
                          errors={result?.errors ?? []}
                          onClickPlan={doNavigate}
                        />
                      )}
                    </SearchResultsLayout>
                  </>
                );
              }}
            </LoadedContent>
          );
        }}
      </LoadedContent>
    </Box>
  );
};

export const SearchResultsContent = ({
  running,
  plans,
  errors,
  onClickPlan,
}: {
  running: boolean;
  plans: Plan[];
  errors: OptimisationFailureReason[];
  onClickPlan: (planCard: PlanCardData) => void;
}) => {
  const { t } = useTenantTranslation();
  const [showFailedResults, setShowFailedResults] = useState(false);
  const { matchingPlans, nonMatchingPlans } = organisePlans(
    plans,
    "display_cost_per_tonne",
    showFailedResults
  );
  const isAdmin = useIsAdmin();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h3">{t("results")}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isAdmin ? (
            <FormLabel sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              {t("showFailedResults") + " "}
              <Switch
                size="small"
                checked={showFailedResults}
                onChange={() => setShowFailedResults(!showFailedResults)}
              />
            </FormLabel>
          ) : null}
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t("sortedBy")}{" "}
            <Box
              component="span"
              sx={{ color: theme.palette.text.primary, fontWeight: 700 }}
            >
              {t("tappedPrice")}
            </Box>
          </Typography>
        </Box>
      </Box>
      {errors.length > 0 && matchingPlans.length === 0 && !running ? (
        <FailedSearch failureReasons={errors} />
      ) : (
        <SuccessfulSearch
          plans={matchingPlans}
          incompleteResults={running}
          primary
          onClickPlan={onClickPlan}
        />
      )}
      {nonMatchingPlans.length > 0 && (
        <Box sx={{ mt: 6 }}>
          <SuccessfulSearch
            plans={nonMatchingPlans}
            incompleteResults={running}
            primary={false}
            onClickPlan={onClickPlan}
          />
        </Box>
      )}
    </>
  );
};

const organisePlans = (
  plans: Plan[],
  property: keyof PeriodSummary,
  showFailedResults: boolean
) => {
  const sortedPlans = sorted(
    plans,
    (plan) => plan.summary.first_period_summary[property] ?? "period"
  );
  return {
    matchingPlans: sortedPlans.filter(
      (plan) => plan.matches_search && shouldShowPlan(plan, showFailedResults)
    ),
    nonMatchingPlans: sortedPlans.filter(
      (plan) => !plan.matches_search && shouldShowPlan(plan, showFailedResults)
    ),
  };
};

const shouldShowPlan = (plan: Plan, showFailedResults: boolean) => {
  return (
    showFailedResults ||
    ["succeeded", "succeeded_suboptimal"].includes(plan.optimisation_status)
  );
};

import equal from "fast-deep-equal";
import { z } from "zod";
import {
  LoadRead,
  NamedPlanMix,
  PlanMixBasket,
} from "src/store/api/generatedApi";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";

export const BasketNumberSchema = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);

export const roundToNearestTonne = (value: number): number => {
  return Math.round(value);
};

export const getMassStatus = (
  current: number,
  target: number
): "under" | "over" | "complete" => {
  const tolerance = 0.5; // 1t tolerance
  if (Math.abs(current - target) <= tolerance) return "complete";
  return current < target ? "under" : "over";
};

export const getDRIMass = (
  materialMasses: Record<number, number>,
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number,
  driYieldAsDecimal: number
) => {
  if (driYieldAsDecimal < 0 || driYieldAsDecimal > 1) {
    throw new Error("DRI yield not gte 0 and lte 1");
  }
  if (
    Object.values(materialYieldsAsDecimal).some(
      (materialYield) => materialYield < 0 || materialYield > 1
    )
  ) {
    throw new Error("Material yield not gte 0 and lte 1");
  }
  return (
    (1 / driYieldAsDecimal) *
    (targetTappedMass -
      typeSafeObjectEntries(materialMasses).reduce(
        (total, [materialId, mass]) =>
          total + mass * materialYieldsAsDecimal[materialId]!,
        0
      ))
  );
};

const cleanMaterialMasses = (materialMasses: Record<string, number>) => {
  return Object.fromEntries(
    Object.entries(materialMasses).filter(([, value]) => value !== 0)
  );
};

const cleanSingleBasket = (planMixBasket: PlanMixBasket) => {
  return {
    material_masses: cleanMaterialMasses(planMixBasket.material_masses),
    total_mass: planMixBasket.total_mass,
  };
};

const cleanBaskets = (baskets: Record<string, PlanMixBasket>) => {
  return Object.fromEntries(
    Object.entries(baskets).map(([key, basket]) => [
      key,
      cleanSingleBasket(basket),
    ])
  );
};

const orderSteelGradeIds = (ids: number[]) => ids.slice(0).sort();

export const areMixesEqual = (mixA: NamedPlanMix[], mixB: NamedPlanMix[]) => {
  return equal(
    mixA
      .map((mix) => ({
        ...mix,
        baskets: cleanBaskets(mix.baskets),
        totals: cleanSingleBasket(mix.totals),
        steel_grade_ids: orderSteelGradeIds(mix.steel_grade_ids),
      }))
      .sort((a, b) => a.mix_id - b.mix_id),
    mixB
      .map((mix) => ({
        ...mix,
        baskets: cleanBaskets(mix.baskets),
        totals: cleanSingleBasket(mix.totals),
        steel_grade_ids: orderSteelGradeIds(mix.steel_grade_ids),
      }))
      .sort((a, b) => a.mix_id - b.mix_id)
  );
};

export const isActive = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).some(
      (basket) => basket.assigned_to_uid !== null
    )
  );
};

export const isPending = (load: LoadRead) => {
  return (
    !isComplete(load) &&
    Object.values(load.baskets).every(
      (basket) => basket.assigned_to_uid === null
    )
  );
};

export const isComplete = (load: LoadRead) => {
  return Object.values(load.baskets).every(
    (basket) => basket.status == "filled"
  );
};

import React from "react";
import { useSearchParams } from "react-router";

import { usePeriod, usePeriods } from "contexts/search/provider";
import { clamp } from "hooks/serialisers/numbers";
import { Loaded, mapLoaded, mapLoadedUnpack } from "models/loaded";

export type Period = number & { __period__: true };

export enum PeriodVariant {
  Schedule,
  Forecast,
}

export const minimumPeriod = 1 as Period;

export const useSetPeriodIndex = () => {
  const [, setSearchParams] = useSearchParams();
  const setPeriod = React.useCallback(
    (period: Period, replace = false) => {
      setSearchParams(
        (searchParams) => {
          searchParams.set("period", period.toString());
          return searchParams;
        },
        { replace }
      );
    },
    [setSearchParams]
  );

  return setPeriod;
};

export const useLoadedPeriodIndex = (): Loaded<Period> => {
  const [searchParams] = useSearchParams();
  const [searchPeriods] = usePeriods();

  const period = parseInt(searchParams.get("period") ?? "");

  if (isNaN(period)) {
    return { status: "error" };
  } else {
    return mapLoaded(
      searchPeriods,
      (periods) =>
        clamp(Number(period), minimumPeriod, periods.length) as Period
    );
  }
};

export const usePeriodIndex = (): Period =>
  mapLoadedUnpack(useLoadedPeriodIndex(), (index) => index) ?? minimumPeriod;

export const usePeriodVariant = (): PeriodVariant => {
  const period = usePeriodIndex();
  switch (period) {
    case minimumPeriod:
      return PeriodVariant.Schedule;
    default:
      return PeriodVariant.Forecast;
  }
};

export const useIsDeployablePeriod = (): boolean | null => {
  const periodIndex = usePeriodIndex();
  const [period] = usePeriod(periodIndex);
  return mapLoadedUnpack(period, (period) => period.is_deployable);
};

import {
  Box,
  IconButton,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import type { JSX } from "react";

type Props = {
  title: string;
  description?: string;
  children: JSX.Element;
  open: boolean;
  doClose: () => void;
  topRight?: JSX.Element;
};

export const PanelLayout = ({
  title,
  description,
  children,
  open,
  doClose,
  topRight,
}: Props) => {
  const theme = useTheme();

  return (
    <Slide in={open} direction="left" mountOnEnter unmountOnExit>
      <Box
        width="calc(100vw - 348px)"
        sx={{
          paddingX: 3,
          paddingY: 2,
          position: "absolute",
          top: 0,
          zIndex: 2,
          height: `calc(100% - ${theme.spacing(4)})`,
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <IconButton
            onClick={() => {
              doClose();
            }}
          >
            <CloseIcon data-testid="EditInput-close" />
          </IconButton>
          <Typography variant="h2">{title}</Typography>
          {topRight ? topRight : null}
        </Stack>
        {description ? (
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            {description}
          </Typography>
        ) : null}
        <Box sx={{ minHeight: "0px", overflow: "hidden" }}>{children}</Box>
      </Box>
    </Slide>
  );
};

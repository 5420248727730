import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTenantTranslation } from "hooks/formatters";
import { LoadingButton } from "@mui/lab";
import { useDeleteSessionMutation } from "store/api/generatedApi";
import { useTenant } from "hooks/settings";

export const DeleteSessionModal = ({
  open,
  close,
  sessionId,
}: {
  open: boolean;
  close: () => void;
  sessionId: number;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const [deleteSession] = useDeleteSessionMutation();
  const tenant = useTenant();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{t("deleteSearch")}</DialogTitle>

      <DialogContent>
        <Typography sx={{ pl: 1 }}>{t("confirmDeleteSearch")}</Typography>

        {error ? (
          <Typography sx={{ pl: 1, pt: 1, color: theme.palette.error.main }}>
            {t("errorDeletingSearch")}
          </Typography>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={close}>
          {t("cancel")}
        </Button>

        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            setLoading(true);
            setError(false);

            void deleteSession({ tenantName: tenant, sessionId })
              .then((result) => {
                if ("error" in result) {
                  setError(true);
                } else {
                  close();
                }
              })
              .catch(() => setError(true))
              .finally(() => setLoading(false));
          }}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

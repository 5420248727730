import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import {
  usePeriodTabTranslation,
  useTenantTranslation,
} from "hooks/formatters";
import { ImportWizardLayout } from "components/common/importWizard/ImportWizard";
import { liftLoadedState, mapLoaded, mapLoadedUnpack } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import {
  SearchSelector,
  SelectedSearch,
} from "components/search/SearchSelector";
import { AvailabilitiesTable } from "./AvailabilitiesTable";
import { removeMaterialsFromOtherSets } from "./preview";
import { useSyncedParametersState } from "contexts/search/parameters";
import { NumberSelector } from "components/common/numberSelector";
import {
  useMaterialPhysics,
  useMaterials,
  useObtainableBundles,
} from "contexts/search/provider";
import { Period, usePeriodIndex } from "hooks/periodIndex";

export const ImportAvailabilityFromSearchButton = () => {
  const { t } = useTenantTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Search />}
        onClick={() => setOpen(true)}
      >
        {t("importFromSearch")}
      </Button>

      {open ? (
        <ImportAvailabilityFromSearchModal close={() => setOpen(false)} />
      ) : null}
    </>
  );
};

const ImportAvailabilityFromSearchModal = ({
  close,
}: {
  close: () => void;
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  const period = usePeriodIndex();
  const [search, setSearch] = useState<SelectedSearch | null>(null);
  const materials = useMaterials();
  const [, setObtainableBundles] = useObtainableBundles(period);

  const [importFromPeriodIndex, setImportFromPeriodIndex] =
    useState<Period>(period);
  const importFromPeriodName = usePeriodTabTranslation()(importFromPeriodIndex);

  const preview = removeMaterialsFromOtherSets(
    materials,
    mapLoaded(
      useSyncedParametersState(search?.search_id ?? null).client,
      ({ periods }) =>
        periods[importFromPeriodIndex - 1]?.obtainable_bundles ?? []
    )
  );

  const materialPhysics = useMaterialPhysics();

  return (
    <ImportWizardLayout
      title={t("importFromSearch")}
      subtitle={
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{ mr: 1, color: theme.palette.text.secondary }}
          >
            {t("useValuesFrom")}:
          </Typography>

          <NumberSelector
            text={importFromPeriodName}
            value={importFromPeriodIndex}
            setValue={setImportFromPeriodIndex as (value: number) => void}
            minimum={1}
            maximum={null}
          />
        </Box>
      }
      explorer={
        <SearchSelector selectedSearch={search} setSelectedSearch={setSearch} />
      }
      preview={
        <LoadedContent
          data={liftLoadedState({ materials, materialPhysics, preview })}
          waiting={null}
          loading={<LinearProgress />}
        >
          {({
            materials: loadedMaterials,
            materialPhysics: loadedMaterialPhysics,
            preview: loadedPreview,
          }) => (
            <AvailabilitiesTable
              materials={loadedMaterials}
              materialPhysics={loadedMaterialPhysics}
              obtainableBundleItems={loadedPreview}
              selectedItemIds={[]}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              setSelectedItemIds={() => {}}
              readOnly
            />
          )}
        </LoadedContent>
      }
      close={close}
      onConfirm={() => {
        const bundles = mapLoadedUnpack(preview, (p) => p);
        if (bundles != null) {
          setObtainableBundles(() => bundles);
        }
      }}
    />
  );
};

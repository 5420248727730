import React from "react";
import dayjs from "dayjs";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";

import { useTenant } from "hooks/settings";

import {
  LoadRead,
  useDeleteLoadMutation,
  useListLoadsQuery,
} from "src/store/api/generatedApi";
import { TitledTabs } from "components/common/titledTabs";
import { useTenantTranslation } from "hooks/formatters";
import { CraneChip } from "./CraneChip";
import { isActive, isComplete, isPending } from "components/crane/utils.ts";

type LoadProps = {
  name: string;
  mixId: number;
  copperGroupWeightPercentMax: number;
};

type QueueProps = {
  activeLoads: LoadRead[];
  pendingLoads: LoadRead[];
};

type CompletedProps = {
  loads: LoadRead[];
};

type TabbedBodyProps = {
  loads: LoadRead[];
};

const Load = ({ name, mixId, copperGroupWeightPercentMax }: LoadProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow: theme.shadows[1],
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 1,
        paddingTop: 1,
        paddingBottom: 1.5,
        paddingX: 2,
        display: "grid",
        flexGrow: 1,
        rowGap: 1,
        gridTemplateColumns: "1fr max-content",
        cursor: "pointer",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
        {name}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: theme.palette.data.orange,
          borderRadius: 2,
          paddingY: "1px",
          paddingX: "2px",
        }}
      >
        Cu {(copperGroupWeightPercentMax * 100).toFixed(0)}
      </Typography>
      <Typography
        variant="body2"
        sx={{ alignSelf: "end", color: theme.palette.text.secondary }}
      >
        {mixId}
      </Typography>
      <CraneChip
        number={1}
        sx={{ gridColumn: "1", justifySelf: "flex-start" }}
      />
    </Box>
  );
};

export const LoadQueue = () => {
  const tenantName = useTenant();

  const createdSince = React.useMemo(() => {
    return dayjs().subtract(1, "day").toISOString();
  }, []);

  const loads = useListLoadsQuery({
    tenantName,
    createdBefore: null,
    createdSince,
  });

  if (loads.data) {
    return <TabbedBody loads={loads.data} />;
  }
  return null;
};

const TabbedBody = ({ loads }: TabbedBodyProps) => {
  const { t } = useTenantTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <Stack sx={{ height: "100%" }}>
      <TitledTabs
        sx={{ marginX: 2 }}
        tabIndex={tabIndex}
        onChange={setTabIndex}
        tabSpecs={[
          {
            title: t("chargeQueue"),
            key: "chargeQueue",
            content: (
              <Queue
                activeLoads={loads.filter((load) => isActive(load))}
                pendingLoads={loads.filter((load) => isPending(load))}
              />
            ),
          },
          {
            title: t("history"),
            key: "history",
            content: (
              <Completed loads={loads.filter((load) => isComplete(load))} />
            ),
          },
        ]}
      />
    </Stack>
  );
};

const Completed = ({ loads }: CompletedProps) => {
  const { t } = useTenantTranslation();
  return (
    <Stack gap={1} paddingTop={1} paddingX={2}>
      {loads.length > 0 ? (
        loads.map((load) => {
          const {
            steel_grade_name,
            mix_id,
            copper_group_weight_percent_max,
            load_id,
          } = load;
          return (
            <Load
              key={load_id}
              name={steel_grade_name}
              mixId={mix_id}
              copperGroupWeightPercentMax={copper_group_weight_percent_max}
            />
          );
        })
      ) : (
        <Typography variant="body1">{t("noCompletedCharges")}</Typography>
      )}
    </Stack>
  );
};

const Queue = ({ activeLoads, pendingLoads }: QueueProps) => {
  const tenantName = useTenant();
  const { t } = useTenantTranslation();
  const [doDeleteLoadMutation] = useDeleteLoadMutation();

  return (
    <Stack
      sx={{
        gap: 3,
        paddingY: 1,
        paddingX: 2,
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Stack gap={1}>
        <Typography variant="h6">{t("active")}</Typography>
        {activeLoads.length > 0 ? (
          activeLoads.map((load) => {
            const {
              steel_grade_name,
              mix_id,
              copper_group_weight_percent_max,
              load_id,
            } = load;
            return (
              <Load
                key={load_id}
                name={steel_grade_name}
                mixId={mix_id}
                copperGroupWeightPercentMax={copper_group_weight_percent_max}
              />
            );
          })
        ) : (
          <Typography variant="body1">{t("noActiveCharges")}</Typography>
        )}
      </Stack>
      <Stack gap={1}>
        <Typography variant="h6">{t("next")}</Typography>
        {pendingLoads.length > 0 ? (
          pendingLoads.map((load) => {
            const {
              steel_grade_name,
              mix_id,
              copper_group_weight_percent_max,
              load_id,
            } = load;
            return (
              <Stack
                key={load_id}
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 0,
                  justifyContent: "space-between",
                }}
              >
                <Load
                  key={load_id}
                  name={steel_grade_name}
                  mixId={mix_id}
                  copperGroupWeightPercentMax={copper_group_weight_percent_max}
                />
                <IconButton
                  onClick={() =>
                    doDeleteLoadMutation({ tenantName, loadId: load_id })
                  }
                  sx={{ paddingRight: 0 }}
                >
                  <RemoveCircleOutlineOutlined />
                </IconButton>
              </Stack>
            );
          })
        ) : (
          <Typography variant="body1">{t("noQueuedCharges")}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

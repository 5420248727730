import fastDeepEqual from "fast-deep-equal";
import { captureException } from "@sentry/browser";

type MaterialException = {
  loadId: number;
  bucketNumber: number;
  unknownMaterialsCount: number;
  missingMaterialIds: number[];
};

export const createMaterialsExceptionHandler = () => {
  const storedMaterialsException: MaterialException[] = [];
  return (materialException: MaterialException) => {
    const isStoredException = storedMaterialsException.find(
      (storedMaterialException) =>
        fastDeepEqual(storedMaterialException, materialException)
    );
    if (!isStoredException) {
      storedMaterialsException.push(materialException);
      captureException(materialException);
    }
  };
};

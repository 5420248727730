import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { UnitType } from "src/store/api/generatedApi";

export const SummaryStat = ({
  label,
  value,
  decimalPlaces,
  units,
  hideDivider,
}: {
  label: string;
  value: number | null;
  decimalPlaces: number;
  units: UnitType | null;
  hideDivider?: boolean;
}) => {
  const { format } = useNumberSerialiser({ decimalPlaces });
  const unitsFormatter = useUnitsFormatter(false);
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <Stack direction="row" gap={3}>
      <Stack>
        <Typography variant="body1Mono">
          {value !== null
            ? `${format(value)}
          ${units == null ? "" : unitsFormatter(units)}`
            : "N/A"}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {t(label)}
        </Typography>
      </Stack>
      {!hideDivider && <Divider orientation="vertical" />}
    </Stack>
  );
};

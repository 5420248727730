import { Route, Routes } from "react-router";

import { SupervisorView } from "components/crane/SupervisorView";
import { NotFound } from "components/notFound";

import {
  RequireIsCraneDriver,
  RequireIsCraneSupervisor,
} from "./authentication";
import { RequireVersionRefresh } from "./dependencies";

import { CraneLoads } from "components/crane/CraneLoads";
import { CraneBasket } from "components/crane/CraneBasket";

export const CraneRoutes = () => {
  return (
    <Routes>
      <Route
        path="/supervisor/*"
        element={
          <RequireIsCraneSupervisor>
            <Routes>
              <Route
                path="/active"
                element={<SupervisorView deploymentType="standard" />}
              />
              <Route
                path="/backup"
                element={<SupervisorView deploymentType="backup" />}
              />
            </Routes>
          </RequireIsCraneSupervisor>
        }
      />

      <Route
        path="/driver/*"
        element={
          <RequireVersionRefresh>
            <RequireIsCraneDriver>
              <Routes>
                <Route index element={<CraneLoads />} />
                <Route path="/:load_id/:basket_id" element={<CraneBasket />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </RequireIsCraneDriver>
          </RequireVersionRefresh>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

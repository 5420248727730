import GoogleIcon from "@mui/icons-material/Google";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Container,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import {
  AuthError,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onIdTokenChanged,
  signInWithPopup,
  User,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { backendApi } from "src/store/api/enhancedApi";

const enum LoginProvider {
  GOOGLE,
  MICROSOFT,
}
interface LocationState {
  from: {
    pathname: string;
  };
}

export const SignInPage = () => {
  const auth = getAuth();
  const googleAuthProvider = new GoogleAuthProvider();
  const microsoftAuthProvider = new OAuthProvider("microsoft.com");
  microsoftAuthProvider.setCustomParameters({
    tenant: "common",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  const nextOrObserver = async (user: User | null) => {
    if (user) {
      if (!tokenRefreshed) {
        // Pick up any permissions changes the API may have performed.
        await user.getIdTokenResult(true);
        setTokenRefreshed(true);
      }
      const locationState = (location.state ?? { from: "/" }) as LocationState;
      void navigate(locationState.from);
    }
  };

  useEffect(() => {
    onIdTokenChanged(auth, (user) => void nextOrObserver(user));
  }, [auth]);

  const handleSignIn = (login: LoginProvider) => {
    switch (login) {
      case LoginProvider.GOOGLE:
        signInWithPopup(auth, googleAuthProvider).catch(handleError);
        break;
      case LoginProvider.MICROSOFT:
        signInWithPopup(auth, microsoftAuthProvider).catch(handleError);
        break;
    }

    // Resetting the whole API state here is probably overkill, but is a good
    // way to guarantee that no information is carried between users until we
    // can work out how to invalidate only the current user query
    dispatch(backendApi.util.resetApiState());
  };

  const handleClick = () => {
    signInWithEmailAndPassword(email, password).catch((err: AuthError) => {
      if (err.message === "auth/invalid-email") {
        setErrorMessage(() => t("invalidEmail").toString());
      } else if (err.message === "auth/wrong-password") {
        setErrorMessage(() => t("invalidPassword").toString());
      }
    });
  };

  const handleError = () => {
    // TODO: implement account linking:
    // https://firebase.google.com/docs/auth/web/account-linking
  };

  return (
    <Container maxWidth="sm" sx={{ height: "100%" }}>
      <Paper
        sx={{
          marginTop: "50%",
        }}
        elevation={2}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          useFlexGap
          sx={{ pl: 8, pr: 8, pt: 4, pb: 4 }}
        >
          <Typography variant="h2" sx={{ mt: 2, margin: "auto" }}>
            {t("scrapChef")}
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={() => handleSignIn(LoginProvider.GOOGLE)}
            startIcon={<GoogleIcon />}
            sx={{ mt: 3, textTransform: "none", borderRadius: 0 }}
          >
            {t("signInWithGoogle")}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            onClick={() => handleSignIn(LoginProvider.MICROSOFT)}
            sx={{ mt: 1, textTransform: "none", borderRadius: 0 }}
            startIcon={
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path
                    fill="currentColor"
                    d="M2 3h9v9H2V3m9 19H2v-9h9v9M21 3v9h-9V3h9m0 19h-9v-9h9v9Z"
                  />
                </svg>
              </SvgIcon>
            }
          >
            {t("signInWithMicrosoft")}
          </LoadingButton>
          <Divider sx={{ m: 1 }}>
            <Typography>{t("loginOr")}</Typography>
          </Divider>
          <TextField
            label={t("loginEmail")}
            type="email"
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                handleClick();
              }
            }}
          />
          <TextField
            label={t("loginPassword")}
            type="password"
            fullWidth
            variant="outlined"
            autoComplete="current-password"
            sx={{ mt: 1, textTransform: "none", borderRadius: 0 }}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                handleClick();
              }
            }}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            variant="outlined"
            sx={{ mt: 2, textTransform: "none", borderRadius: 0 }}
            onClick={handleClick}
          >
            {t("logIn")}
          </LoadingButton>
          {error ? (
            <Alert sx={{ mt: 1 }} severity="error">
              {t("loginFailed")} {errorMessage}
            </Alert>
          ) : null}
        </Stack>
      </Paper>
    </Container>
  );
};
